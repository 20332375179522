import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { asyncSubmit, listenAccount, passwordHash } from "utils";
import { NAV, useLoc } from "config";
import { useCloud } from "cloud";
import { FormikErrors, FormikHelpers, useFormik } from "formik";
import { FormAlert, FormField } from "components";

type WelcomeProps = {};

type Values = {
    email: string;
    password: string;
};

export function Login(_props: WelcomeProps) {
    const loc = useLoc();
    const cloud = useCloud();

    const formik = useFormik<Values>({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: async ({ email, password }: Values, { setSubmitting }: FormikHelpers<Values>) => {
            await asyncSubmit(
                async () => {
                    await cloud.login(email, passwordHash(email, password));
                    navigate(NAV.projects);
                },
                setSubmitting,
                setError,
            )();
        },
        validate: ({ email, password }: Values) => {
            const errors: FormikErrors<Values> = {};
            if (email === "") {
                errors.email = loc.validation.required;
            }
            if (password === "") {
                errors.password = loc.validation.required;
            }
            return errors;
        },
    });
    const [error, setError] = useState<string | undefined>();

    const navigate = useNavigate();

    useEffect(() => listenAccount(cloud, undefined, navigate), [cloud, navigate]);

    return (
        <div className="ec-centered-card p-4" style={{ maxWidth: "30em" }}>
            <div className="ec-card-header mb-3">{loc.login.welcome}</div>
            <div className="ec-card-body mb-3">{loc.login.text}</div>
            <Form onSubmit={formik.handleSubmit}>
                <FormField type="email" field="email" label={loc.login.email} formik={formik} />
                <FormField
                    type="password"
                    field="password"
                    label={loc.login.password}
                    formik={formik}
                />
                <div className="d-grid gap-2">
                    <Button
                        variant="primary"
                        size="lg"
                        type="submit"
                        disabled={formik.isSubmitting}
                    >
                        {loc.login.login}
                    </Button>
                    <div className="text-center">
                        <Link
                            style={{
                                color: "grey",
                                textDecoration: "none",
                            }}
                            to={NAV.forgotPassword}
                        >
                            {loc.login.forgotPassword}
                        </Link>
                    </div>
                </div>
            </Form>
            <FormAlert error={error} setError={setError} />
            <div className="ec-card-body text-center mt-3">
                {loc.login.dontHaveAnAccount}&nbsp;
                <Link className="ec-card-link" to={NAV.register}>
                    {loc.login.signUp}
                </Link>
            </div>
        </div>
    );
}
