import { CloudProject } from "cloud";
import { Button } from "react-bootstrap";
import { useLoc } from "config";
import { basicAuth } from "utils";
import classNames from "classnames";

import gettingStartedStyles from "./GettingStarted.module.scss";
const styles: any = gettingStartedStyles;

type GettingStartedProps = {
    project: CloudProject;
};

export function GettingStarted({ project }: GettingStartedProps) {
    const loc = useLoc().gettingStarted;

    const sample = (params: {
        title: string;
        purpose: string;
        directions: string;
        request: string;
        response: string;
    }) => {
        const request = params.request
            .replace("{project}", project?.id)
            .replace(
                "{secret-auth}",
                project?.secretRequired
                    ? `--header 'Authorization: ${basicAuth(project?.secret)}' \\\n`
                    : "",
            );

        return (
            <div className="p-4 pt-3 pb-3 ec-sub-card">
                <div className="ec-card-header">{params.title}</div>
                <div className="ec-card-body">{params.purpose}</div>
                <div className="ec-card-body">{params.directions}</div>
                <pre className="ec-card-code mb-1">{request}</pre>
                <Button className="w-100" size="sm" variant="outline-info">
                    {loc.copy}
                </Button>
                <div className="ec-card-body">{loc.responseTitle}</div>
                <pre className="ec-card-code">{params.response}</pre>
            </div>
        );
    };
    return (
        <div className={classNames(styles.GettingStarted, "ec-card p-3")}>
            <div className="ec-card-header m-0 mb-3">{loc.title}</div>
            {sample(loc.samples[0])}
        </div>
    );
}
