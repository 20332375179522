import { useState } from "react";
import { CloudAccount, useCloud } from "cloud";
import { Button, Form, Stack } from "react-bootstrap";
import { useLoc } from "config";
import { FormField, FormAlert } from "components";
import { FormikErrors, FormikHelpers, useFormik } from "formik";
import { asyncSubmit, passwordHash } from "utils";

type AccountPasswordProps = {
    account: CloudAccount;
};

interface Values {
    oldPassword: string;
    password: string;
    confirmPassword: string;
}

export function AccountPassword({ account }: AccountPasswordProps) {
    const cloud = useCloud();
    const loc = useLoc();
    const [error, setError] = useState<string | undefined>();
    const formik = useFormik<Values>({
        initialValues: {
            oldPassword: "",
            password: "",
            confirmPassword: "",
        },
        onSubmit: async (
            { oldPassword, password }: Values,
            { setSubmitting }: FormikHelpers<Values>,
        ) => {
            await asyncSubmit(
                async () => {
                    await cloud.changePassword(
                        passwordHash(account.email, oldPassword),
                        passwordHash(account.email, password),
                    );
                    formik.resetForm();
                },
                setSubmitting,
                setError,
            )();
        },
        validate: ({ password, confirmPassword }: Values) => {
            const errors: FormikErrors<Values> = {};
            if (password === "") {
                errors.password = loc.validation.required;
            }
            if (password !== confirmPassword) {
                errors.confirmPassword = loc.validation.confirmPasswordMismatch;
            }
            return errors;
        },
    });

    return (
        <div className="ec-card p-4">
            <Form onSubmit={formik.handleSubmit}>
                <div className="ec-card-body">
                    <FormField
                        type="password"
                        field="oldPassword"
                        label={loc.accountPassword.oldPassword}
                        formik={formik}
                    />
                    <FormField
                        type="password"
                        field="password"
                        label={loc.accountPassword.password}
                        formik={formik}
                    />
                    <FormField
                        type="password"
                        field="confirmPassword"
                        label={loc.accountPassword.confirmPassword}
                        formik={formik}
                    />
                    <Stack
                        direction="horizontal"
                        gap={3}
                        style={{
                            marginLeft: 0,
                            width: "100%",
                        }}
                    >
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={!formik.dirty || formik.isSubmitting}
                        >
                            {loc.accountPassword.save}
                        </Button>
                        <Button
                            type="reset"
                            variant="secondary"
                            onClick={formik.handleReset}
                            disabled={!formik.dirty || formik.isSubmitting}
                        >
                            {loc.accountPassword.cancel}
                        </Button>
                    </Stack>
                </div>
            </Form>
            <FormAlert error={error} setError={setError} />
        </div>
    );
}
