import { useLoc } from "../config";

export type PropSize = "sm" | "md" | "lg";

type PropProps = {
    key: string;
    value: any;
    title?: any;
    size?: PropSize;
    actions?: any[];
};

type PropListProps = {
    props: PropProps[];
    titlePosition?: "left" | "top";
    valueAlignment?: "left" | "right";
};

export type CopyProps = {
    value: string;
    size?: PropSize;
};

export function getFontSize(size?: PropSize): string | undefined {
    return {
        sm: "smaller",
        md: undefined,
        lg: "larger",
    }[size ?? "md"];
}

export function Copy(props: CopyProps) {
    const loc = useLoc().props;
    return (
        <span
            style={{
                fontSize: getFontSize(props.size),
                paddingTop: "6pt",
                paddingLeft: "4pt",
                cursor: "pointer",
                color: "darkgrey",
            }}
            title={loc.copyToClipboard}
            onClick={async () => {
                await navigator.clipboard.writeText(`${props.value}`);
            }}
            className="material-symbols-rounded"
        >
            content_copy
        </span>
    );
}

export function PropList({ props, valueAlignment, titlePosition }: PropListProps) {
    const align = valueAlignment ?? "left";
    const titleAtLeft = (titlePosition ?? "left") === "left";
    const withTitles = props.find(x => x.title);
    let style = {
        paddingTop: "2pt",
        paddingBottom: "2pt",
    };
    return (
        <table style={{ width: "100%" }}>
            <tbody>
                {props.map(prop => {
                    const { key, title, value, size, actions } = prop;
                    const fontSize = getFontSize(size);

                    const isScalar =
                        typeof value === "bigint" ||
                        typeof value === "boolean" ||
                        typeof value === "string" ||
                        typeof value === "number";
                    const titleCell = withTitles ? (
                        <td
                            valign="middle"
                            style={{
                                ...style,
                                paddingRight: "1em",
                                color: "darkgrey",
                                fontSize,
                            }}
                        >
                            {title ?? ""}
                        </td>
                    ) : null;
                    const valueCell = (
                        <td
                            align={align}
                            valign="middle"
                            style={{
                                ...style,
                                maxWidth: "100%",
                                overflow: "hidden",
                            }}
                        >
                            {actions ? (
                                <table>
                                    <tbody>
                                        <tr>
                                            <td
                                                valign="middle"
                                                style={{
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {isScalar ? (
                                                    <span style={{ fontSize }}>
                                                        {value.toString()}
                                                    </span>
                                                ) : (
                                                    value
                                                )}
                                            </td>
                                            {actions.map(x => (
                                                <td key={x.key} valign="middle">
                                                    {x}
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            ) : isScalar ? (
                                <span
                                    style={{
                                        fontSize,
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {value.toString()}
                                </span>
                            ) : (
                                value
                            )}
                        </td>
                    );
                    return titleAtLeft ? (
                        <tr key={key}>
                            {titleCell}
                            {valueCell}
                        </tr>
                    ) : (
                        [
                            <tr key={`${key}.0`}>{titleCell}</tr>,
                            <tr key={`${key}.1`}>{valueCell}</tr>,
                        ]
                    );
                })}
            </tbody>
        </table>
    );
}
