import { Alert } from "react-bootstrap";

type FormAlertProps = {
    error: string | undefined;
    setError: (error: string | undefined) => void;
};

export function FormAlert({ error, setError }: FormAlertProps) {
    return (
        <Alert
            show={!!error}
            variant="danger"
            className="mt-3"
            dismissible={true}
            onClose={() => setError(undefined)}
        >
            {error}
        </Alert>
    );
}
