const FLDNetworkName = "fld";

const getPreparedEndpoint = (endpoint: string, replaceOnWSS: boolean = false): string => {
    let preparedEndpoint = endpoint;

    if (endpoint.includes(FLDNetworkName)) {
        preparedEndpoint = endpoint
            .split("/")
            .filter((_, index, arr) => index !== arr.length - 2)
            .join("/");
    }
    if (replaceOnWSS) preparedEndpoint = preparedEndpoint.replace("https://", "wss://");

    return preparedEndpoint;
};

export { getPreparedEndpoint };
