import React, { useContext } from "react";
import { getLoc, Lang, LangLoc } from "./loc";
import { defaultTheme, Theme, ThemeNames, Themes, ThemeStyles } from "./theme";
import { useCloud } from "../cloud";
import { LIGHT_STYLES } from "./lightStyles";
import { DARK_STYLES } from "./darkStyles";

export { NAV, ProjectSection, AccountSection } from "./nav";

export type { LangLoc } from "./loc";
export type { ThemeStyles } from "./theme";

type Config = {
    lang: Lang;
    theme: Theme;

    toggleTheme: () => Theme;
    setTheme: (theme: Theme) => Theme;
    loadTheme: () => Theme;
    getStyles: () => ThemeStyles;
};

const ConfigContext = React.createContext<Config>({
    lang: Lang.en,
    theme: defaultTheme,

    toggleTheme() {
        if (!this) return defaultTheme;
        const theme = this.theme.name === ThemeNames.Light ? Themes.dark : Themes.light;

        return this.setTheme(theme);
    },

    getStyles(): ThemeStyles {
        if (!this) return LIGHT_STYLES;
        if (this.theme === Themes.dark) {
            return DARK_STYLES;
        }
        return LIGHT_STYLES;
    },

    setTheme(theme: Theme) {
        if (!this) return defaultTheme;
        this.theme = theme;
        window.document.body.className = `${theme.name}-theme`;
        window.localStorage.setItem("THEME", theme.name);

        return this.theme;
    },

    loadTheme() {
        if (!this) return defaultTheme;
        const name = window.localStorage.getItem("THEME") ?? Themes.light.name;
        const theme = Themes[name as ThemeNames] ?? defaultTheme;

        return this.setTheme(theme);
    },
});

export function useLoc(): LangLoc {
    const config = useContext(ConfigContext);
    return getLoc(config.lang);
}

export function useTheme(): Theme {
    const config = useContext(ConfigContext);
    return config.theme;
}

export function useConfig(): Config {
    return useContext(ConfigContext);
}

export function useStyles(): ThemeStyles {
    const config = useContext(ConfigContext);
    return config.getStyles();
}
