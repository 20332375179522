import React, { useCallback, useMemo, useRef, useState } from "react";
import moment from "moment/moment";
import classnames from "classnames";
import { Popover } from "@mui/material";
import { FormControlLabel, Radio } from "../index";
import CloseIcon from "@mui/icons-material/Close";

import type { ChangeFilterType, CustomTimeRangeType, ProjectLogFilters } from "../../../../types";
import { CustomTimeRangeBorder } from "../../../../types";
import { DateTimePicker } from "./components/DateTimePicker";

import flexStyles from "styles/flex/flex.module.scss";
import styles from "./TimeRangePicker.module.scss";

type TimeRange = Pick<ProjectLogFilters, "timestampTo"> &
    Pick<ProjectLogFilters, "timestampFrom"> &
    Pick<ProjectLogFilters, "timeRange">;

type FiltersProps = TimeRange & {
    onChange: (v: TimeRange) => void;
};

const getSelectedTimeString = (preparedTimeRange: CustomTimeRangeType) => {
    const from = preparedTimeRange.timestampFrom ? preparedTimeRange.timestampFrom * 1000 : undefined;
    const to = preparedTimeRange.timestampTo ? preparedTimeRange.timestampTo * 1000 : undefined;

    const fromSubstring = from || from === 0 ? moment(from).format("DD-MM-YYYY HH:mm:ss") : "Now";
    const toSubstring = ` - 
    ${to || to === 0 ? `${moment(to).format("DD-MM-YYYY HH:mm:ss")}` : "Now"}`;

    return `${fromSubstring}${toSubstring}`;
};

const TimeRangePicker: React.FC<FiltersProps> = ({
    timestampTo,
    timestampFrom,
    timeRange,
    onChange,
}) => {
    const dateTimeTriggerRef = useRef(null);
    const [isDateTimePopoverOpen, setIsDateTimePopoverOpen] = useState<boolean>(false);

    const preparedTimeRange: TimeRange = useMemo(
        () =>
            timestampTo && timestampFrom
                ? { timestampTo, timestampFrom, timeRange: undefined }
                : ({ timestampTo: Date.now(), timestampFrom: Date.now(), timeRange } as TimeRange),
        [timestampTo, timestampFrom, timeRange],
    );

    const onTimeChange = useCallback(
        (value: number | null, timeBorder: CustomTimeRangeBorder) => {
            if (value === null) {
                const newTimeFilter = { ...preparedTimeRange };
                newTimeFilter[timeBorder] = Math.floor(Date.now() / 1000);

                onChange(newTimeFilter);
            } else {
                onChange({
                    ...preparedTimeRange,
                    [timeBorder]: Math.floor(Number(value) / 1000),
                });
            }
        },
        [onChange, preparedTimeRange],
    );

    return (
        <>
            <FormControlLabel
                ref={dateTimeTriggerRef}
                control={
                    <Radio
                        size="small"
                        value={undefined}
                        onClick={() => setIsDateTimePopoverOpen(prev => !prev)}
                        checked={!timeRange}
                    />
                }
                label="Custom"
            />
            <Popover
                open={isDateTimePopoverOpen}
                onClose={() => setIsDateTimePopoverOpen(false)}
                anchorEl={dateTimeTriggerRef?.current}
            >
                <div className={styles.TimeRangePicker}>
                    <div
                        className={styles.TimeRangePicker__closeIcon}
                        onClick={() => setIsDateTimePopoverOpen(false)}
                    >
                        <CloseIcon />
                    </div>
                    <div className={styles.TimeRangePicker__body}>
                        <section
                            className={classnames(flexStyles.flex, flexStyles.directionColumn)}
                        >
                            <DateTimePicker
                                value={preparedTimeRange.timestampFrom * 1000 ?? Date.now()}
                                onChange={newValue =>
                                    onTimeChange(newValue, CustomTimeRangeBorder.From)
                                }
                                label="From"
                            />
                        </section>
                        <section
                            className={classnames(flexStyles.flex, flexStyles.directionColumn)}
                        >
                            <DateTimePicker
                                value={preparedTimeRange.timestampTo * 1000 || Date.now()}
                                onChange={newValue =>
                                    onTimeChange(newValue, CustomTimeRangeBorder.To)
                                }
                                label="To"
                            />
                        </section>
                    </div>
                    <footer className={styles.TimeRangePicker__footer}>
                        {Boolean(
                            preparedTimeRange.timestampFrom * 1000 ??
                                preparedTimeRange.timestampTo * 1000,
                        )
                            ? getSelectedTimeString(preparedTimeRange)
                            : "Select dates"}
                    </footer>
                </div>
            </Popover>
        </>
    );
};

export { TimeRangePicker };
