import React, { useContext } from "react";
import { ProjectLogFilters, ProjectLogsType, ProjectSummaryType } from "../screens/explorer/types";

const DEFAULT_API_URL = process.env.REACT_APP_API_URL;
const AUTH_TOKEN_HEADER = "token";
const AUTH_TOKEN_STORAGE_KEY = "api-auth-token";

type CloudOnAuth = (isAuth: boolean) => void;
type CloudOnAccount = (account?: CloudAccount) => void;
type CloudOnError = (error: Error) => void;

export type CloudPlan = {
    id: string;
    name: string;
};

export type CloudNetwork = {
    id: string;
    name: string;
    topupEnabled: boolean;
    logo?: string;
};

export type CloudAccount = {
    id: string;
    email: string;
    organizationName: string;
    organizationUrl: string;
    organizationSize: string;
    organizationCategory: string;
    userRole: string;
    isVerified: boolean;
    plan: CloudPlan;
    balance: number;
    projects: CloudProject[];
};

export type CloudAccountUpdate = {
    email?: string;
    company?: string;
    password?: string;
    planId?: String;
};

export type CloudProject = {
    id: string;
    name: string;
    secret: string;
    secretRequired: boolean;
    networks: CloudProjectNetwork[];
};

export type CloudProjectUpdate = {
    id?: string;
    name?: string;
    secretRequired?: boolean;
};

export type CloudProjectNetwork = {
    network: CloudNetwork;
    endpoints: string[];
};

export enum KnownApiError {
    Unauthorised = "UNAUTHORISED",
    AccountDoesNotExist = "ACCOUNT_DOES_NOT_EXIST",
    AccountDoesNotExistOrPasswordIsIncorrect = "ACCOUNT_DOES_NOT_EXIST_OR_PASSWORD_IS_INCORRECT",
    AccountIsNotVerified = "ACCOUNT_IS_NOT_VERIFIED",
    AccountAlreadyRegistered = "ACCOUNT_ALREADY_REGISTERED",
    InvalidDatabaseUrl = "INTERNAL_SERVER_ERROR",
    DataError = "INTERNAL_SERVER_ERROR",
    DbError = "INTERNAL_SERVER_ERROR",
    ServiceError = "INTERNAL_SERVER_ERROR",
    InvalidUserData = "INVALID_USER_DATA",
    InvalidAccountData = "INVALID_ACCOUNT_DATA",
    InvalidVerificationCode = "INVALID_VERIFICATION_CODE",
    MailError = "INTERNAL_SERVER_ERROR",
    ProjectNotFound = "PROJECT_NOT_FOUND",
    ProjectSecretRequired = "PROJECT_SECRET_REQUIRED",
    ProjectSecretIsIncorrect = "PROJECT_SECRET_IS_INCORRECT",
    LimitReached = "LIMIT_REACHED",
    OldPasswordMismatch = "OLD_PASSWORD_MISMATCH",
}

export type ApiError = KnownApiError | string;

type CloudEventHandler = {
    auth?: CloudOnAuth;
    account?: CloudOnAccount;
    error?: CloudOnError;
};

export class Cloud {
    private readonly url: string;
    private authToken?: string;
    private eventHandlers: CloudEventHandler[] = [];
    account?: CloudAccount;
    project?: CloudProject;
    isAuth = false;

    constructor(url?: string) {
        this.url = url ?? DEFAULT_API_URL ?? "http://localhost:8000";
        this.authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY) ?? undefined;
        this.isAuth = !!this.authToken;
        (async () => {
            await this.refreshAccount();
        })();
    }

    on(handler: { auth?: CloudOnAuth; account?: CloudOnAccount }) {
        this.eventHandlers.push(handler);
        return handler;
    }

    remove(handler: CloudEventHandler) {
        this.eventHandlers = this.eventHandlers.filter(x => x !== handler);
    }

    async login(email: string, password: string) {
        const token = (
            await this.apiQuery(
                `
            mutation login($email: String, $password: String) {
                login(email: $email, password: $password)
            }
        `,
                {
                    email,
                    password,
                },
            )
        ).login;
        this.setToken(token);
        await this.refreshAccount();
    }

    async signUp(account: {
        email: string;
        password: string;
        organizationName: string;
        organizationUrl: string;
        organizationSize: string;
        organizationCategory: string;
        userRole: string;
    }) {
        const token = (
            await this.apiQuery(
                `
            mutation signUp(
                $email: String!,
                $password: String!,
                $organizationName: String!,
                $organizationUrl: String!,
                $organizationSize: String!,
                $organizationCategory: String!,
                $userRole: String!,
            ) {
                signUp(
                    email: $email
                    password: $password
                    organizationName: $organizationName
                    organizationUrl: $organizationUrl
                    organizationSize: $organizationSize
                    organizationCategory: $organizationCategory
                    userRole: $userRole
                )
            }
        `,
                account,
            )
        ).signUp;
        this.setToken(token);
        await this.refreshAccount();
    }

    async logout() {
        this.setToken();
        await this.refreshAccount();
    }

    async saveProject(project: CloudProjectUpdate): Promise<string> {
        const id = (
            await this.apiQuery(
                `
            mutation save($project: ProjectUpdate) {
                saveProject(project: $project)
            }
        `,
                {
                    project,
                },
            )
        ).saveProject;
        await this.refreshAccount();
        return id;
    }

    async deleteProject(id: string) {
        await this.apiQuery(
            `
            mutation delete($id: String) {
                deleteProject(id: $id)
            }
        `,
            {
                id,
            },
        );
        await this.refreshAccount();
    }

    private setToken(token?: string) {
        this.authToken = token;
        this.isAuth = !!token;
        if (token) {
            localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, token);
        } else {
            localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
        }
        this.fireAuth(this.isAuth);
    }

    private fireAuth(isAuth: boolean) {
        for (const handler of this.eventHandlers) {
            handler.auth?.(isAuth);
        }
    }

    private fireAccount(account?: CloudAccount) {
        for (const handler of this.eventHandlers) {
            handler.account?.(account);
        }
    }

    private fireError(err: Error) {
        for (const handler of this.eventHandlers) {
            handler.error?.(err);
        }
    }

    private async refreshAccount() {
        try {
            this.account = this.isAuth ? await this.apiAccount() : undefined;
            this.fireAccount(this.account);
        } catch (err) {
            this.fireError(new Error(`Failed to load account information: ${err}`));
        }
    }

    private async apiAccount(): Promise<CloudAccount | undefined> {
        return (
            await this.apiQuery(
                `
            query {
              account {
                id
                email
                isVerified
                organizationName
                organizationUrl
                organizationSize
                organizationCategory
                userRole
                plan { id name }
                balance    
                projects {
                  id
                  name
                  secret
                  secretRequired
                  networks {
                    network { id name topupEnabled }
                    endpoints
                  }
                }
              }
            }            
        `,
                {},
            )
        ).account;
    }

    private async apiQuery(query: string, vars: object) {
        let response, body;
        try {
            const headers: { [name: string]: string } = {
                "Content-Type": "application/json",
            };
            if (this.authToken) {
                headers[AUTH_TOKEN_HEADER] = this.authToken;
            }
            response = await fetch(this.url, {
                method: "POST",
                headers,
                body: JSON.stringify({
                    query,
                    variables: vars,
                }),
            });
            body = response.status === 200 ? await response.json() : undefined;
        } catch (e: any) {
            throw new CloudError(e.message, KnownApiError.ServiceError);
        }
        if (body) {
            if (body.data !== null && body.data !== undefined) {
                return body.data;
            }
            if (Array.isArray(body.errors) && body.errors.length > 0) {
                const err = body.errors[0];
                let code = String(
                    err.extensions?.code ?? KnownApiError.ServiceError,
                ) as KnownApiError;
                if (code === KnownApiError.Unauthorised) {
                    await this.logout();
                }
                throw new CloudError(err.message, code);
            }
        }
        throw new CloudError(await response.text(), String(response.status) as KnownApiError);
    }

    async verify(code: string) {
        const token = (
            await this.apiQuery(
                `
            mutation verify($code: String) {
                verifyAccount(code: $code)
            }
        `,
                {
                    code,
                },
            )
        ).verifyAccount;
        await this.setToken(token);
        await this.refreshAccount();
    }

    async resetPassword(code: string, newPassword: string) {
        const token = (
            await this.apiQuery(
                `
            mutation resetPassword($code: String, $newPassword: String) {
                resetPassword(code: $code, newPassword: $newPassword)
            }
        `,
                {
                    code,
                    newPassword,
                },
            )
        ).resetPassword;
        await this.setToken(token);
        await this.refreshAccount();
    }

    async requestResetPassword(email: string) {
        await this.apiQuery(
            `
            mutation request($email: String) {
                requestResetPassword(email: $email)
            }
        `,
            {
                email,
            },
        );
    }

    async changePassword(oldPassword: string, newPassword: string) {
        await this.apiQuery(
            `
            mutation changePassword($oldPassword: String, $newPassword: String) {
                changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
            }
        `,
            {
                oldPassword,
                newPassword,
            },
        );
    }

    async updateProfile(profile: {
        organizationName: string;
        organizationUrl: string;
        organizationSize: string;
        organizationCategory: string;
        userRole: string;
    }) {
        await this.apiQuery(
            `
            mutation updateProfile(
                $organizationName: String!,
                $organizationUrl: String!,
                $organizationSize: String!,
                $organizationCategory: String!,
                $userRole: String!,
            ) {
                updateProfile(
                    organizationName: $organizationName
                    organizationUrl: $organizationUrl
                    organizationSize: $organizationSize
                    organizationCategory: $organizationCategory
                    userRole: $userRole
                )
            }
        `,
            profile,
        );
        await this.refreshAccount();
    }

    async submitFeedback(question: string, answer: string) {
        await this.apiQuery(
            `
            mutation submitFeedback(
                $question: String!,
                $answer: String!,
            ) {
                submitFeedback(
                    question: $question
                    answer: $answer
                )
            }
        `,
            {
                question,
                answer,
            },
        );
    }

    async topupAddress(network: CloudNetwork, address: string) {
        await this.apiQuery(
            `
            mutation topupAddress(
                $network: String!,
                $address: String!,
            ) {
                topupAddress(
                    network: $network
                    address: $address
                )
            }
        `,
            {
                network: network.id,
                address,
            },
        );
    }

    async getAccountSummary(
        from: number,
        to: number,
    ): Promise<{ analytics: { accountSummary: { points: number } } }> {
        return await this.apiQuery(
            `
            query {
                analytics {
                    accountSummary(timestampFrom:${from} , timestampTo:${to}) {
                        points
                    }
                }
            }
        `,
            {},
        );
    }

    async getAllProjectLogs(
        filters: ProjectLogFilters,
    ): Promise<{ analytics: { projectLogs: ProjectLogsType } }> {
        const {
            projectId,
            search,
            resTimeMax,
            resTimeMin,
            timestampFrom,
            timestampTo,
            last,
            first,
            after,
            before,
            errorsOnly,
        } = filters;
        console.log(filters);

        let params = `timestampFrom: ${timestampFrom}`;
        if (timestampTo) params += `, timestampTo: ${timestampTo}`;
        if (projectId) params += `, project: "${projectId}"`;
        if (search) params += `, search: "%${search}%"`;
        if (resTimeMin) params += `, resTimeMin: ${resTimeMin}`;
        if (resTimeMax) params += `, resTimeMax: ${resTimeMax}`;
        if (first) params += `, first: ${first}`;
        if (last) params += `, last: ${last}`;
        if (before) params += `, before: ${before}`;
        if (after) params += `, after: ${after}`;
        if (errorsOnly !== undefined) params += `, errorsOnly: ${errorsOnly}`;

        return await this.apiQuery(
            `
            query {
                analytics {
                    projectLogs(${params}) {
                        edges {
                            node {
                                requestId
                                request
                                timeMs
                                errCode
                                httpStatus
                                resTime
                                project
                                network
                                eventType
                                protocol
                                errMessage
                                bytes
                            }
                        },
                        nodes {
                            requestId
                            request
                            timeMs
                            errCode
                            httpStatus
                            resTime
                            project
                            network
                            eventType
                            protocol
                            errMessage
                            bytes
                        }
                    }
                }
            }
        `,
            {},
        );
    }

    async getAllProjectSummary(): Promise<{ analytics: { projectSummary: ProjectSummaryType } }> {
        return await this.apiQuery(
            `
            query {
                analytics {
                    projectSummary() {
                        pointsPerSec5M,
                        medianResTime5M,
                        successRate1H,
                        totalRequests1H,
                    }
                }
            }
        `,
            {},
        );
    }

    async getProjectSummary(
        projectId: string,
    ): Promise<{ analytics: { projectSummary: ProjectSummaryType } }> {
        return await this.apiQuery(
            `
            query {
                analytics {
                    projectSummary(project: "${projectId}") {
                        pointsPerSec5M,
                        medianResTime5M,
                        successRate1H,
                        totalRequests1H,
                    }
                }
            }
        `,
            {},
        );
    }
}

export class CloudError extends Error {
    code: KnownApiError;

    constructor(msg: string, code: KnownApiError) {
        super(msg);
        this.code = code;
    }
}

const CloudContext = React.createContext<Cloud>(new Cloud());
export const SelectedProjectIdContext =  React.createContext<{ projectId: string | undefined, setProjectId: any }>({ projectId: undefined, setProjectId: () => {} });

export function useCloud(): Cloud {
    return useContext(CloudContext);
}

export function useProjectId(): { projectId: string | undefined, setProjectId: any } {
    return useContext(SelectedProjectIdContext);
}
