import React from "react";
import moment from "moment";
import classnames from "classnames";
import { Button, TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers";

import flexStyles from "styles/flex/flex.module.scss";
import marginStyles from "styles/margin/margin.module.scss";
import "./DateTimePicker.scss";

type DateTimePickerProps = {
    value: number;
    onChange: (value: number | null) => void;
    label: string;
};

const DateTimePicker: React.FC<DateTimePickerProps> = ({ value, onChange, label }) => (
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <StaticDateTimePicker
            className="DateTimePicker"
            value={value}
            label={label}
            onChange={value => onChange(Number(moment(value).format("x")))}
            ampm={false}
            closeOnSelect={false}
            //@ts-ignore
            renderInput={params => <TextField {...params} />}
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
        />
        <div
            className={classnames(
                flexStyles.flex,
                flexStyles.justifyContentSpaceBetween,
                marginStyles.topAuto,
            )}
        >
            <Button onClick={() => onChange(Date.now())}>Today</Button>
            <Button onClick={() => onChange(null)}>Clear</Button>
        </div>
    </LocalizationProvider>
);

export { DateTimePicker };
