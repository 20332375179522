import React, { useMemo, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { CloudNetwork, CloudProject, CloudProjectNetwork } from "cloud";
import { useLoc } from "config";
import { Copy } from "components";
import { TopupDialog } from "screens/dashboard/project/TopupDialog";
import { Tooltip } from "@mui/material";
import classnames from "classnames";
import { getPreparedEndpoint } from "./helpers";

import projectEndpointsStyles from "./ProjectEndpoints.module.scss";
const styles: any = projectEndpointsStyles;

type ProjectEndpointsProps = {
    project: CloudProject;
};

const TONNetworkName = "ton";

export function ProjectEndpoints(props: ProjectEndpointsProps) {
    const project = props.project;
    const [topupNetwork, setTopupNetwork] = useState<CloudNetwork | undefined>(undefined);

    const networks = useMemo(() => {
        const withOutTon = project.networks.findIndex(net => net.network.name === 'The Open Network (TON)') === -1
        if (withOutTon || project.networks.length === 1 || !project.networks) {
            return project.networks
        } else {
            const nets = project.networks.filter(net => net.network.name !== 'The Open Network (TON)').map((net: CloudProjectNetwork) => {
                if (net.network.id === "everscale_main") {
                    return {
                        ...net,
                        network: {
                            ...net.network,
                            name: "Everscale mainnet"
                        },
                    };
                }
                if (net.network.id === "everscale_dev") {
                    return {
                        ...net,
                        network: {
                            ...net.network,
                            name: "Everscale devnet"
                        },
                    };
                }

                return net;
            });

            return [
                ...nets.slice(0, 2),
                {
                    network: {
                        id: 'venom_mainnet',
                        name: "Venom mainnet",
                        topupEnabled: false,
                        logo: "/venom-seeklogo.svg",
                    },
                    endpoints: [`https://venom-mainnet.evercloud.dev/${project.id}/graphql`]
                },
                {
                    network: {
                        id: 'venom_testnet',
                        name: "Venom testnet",
                        topupEnabled: false,
                        logo: "/venom-seeklogo.svg",

                    },
                    endpoints: [`https://venom-testnet.evercloud.dev/${project.id}/graphql`]
                },
                // {
                //     network: {
                //         id: 'venom_devnet',
                //         name: "Venom devnet",
                //         topupEnabled: true,
                //
                //     },
                //     endpoints: [
                //         `https://venom-devnet.evercloud.dev/${project.id}/graphql`
                //     ],
                // },
                ...nets.slice(2, 100),
            ]
        }
    }, [project.networks])

    return (
        <Stack direction="vertical" gap={4}>
            <div className="ec-card p-4">
                <div className={classnames(styles.ProjectEndpoints__body, "ec-card-body")}>
                    {networks.map(network => (
                        <Network
                            network={network}
                            project={project}
                            setTopupNetwork={() => {
                                if (network.network.id === 'venom_devnet') {
                                    window.open('https://devnetfaucet.com/',  '_blank');
                                } else {
                                    setTopupNetwork(network.network)
                                }
                            }}
                            key={network.network.name}
                        />
                    ))}
                </div>
            </div>
            <TopupDialog
                network={topupNetwork}
                show={!!topupNetwork}
                onHide={() => setTopupNetwork(undefined)}
            />
        </Stack>
    );
}

type NetworkProps = {
    network: CloudProjectNetwork;
    project: CloudProject;
    setTopupNetwork: () => void;
};

const Network: React.FC<NetworkProps> = ({ network, project, setTopupNetwork }) => (
    <div className={styles.Network}>
        <NetworkTitle project={project} network={network} setTopupNetwork={setTopupNetwork} />
        {network.endpoints.map(endpoint => (
            <div className={styles.Network__endpoints} key={endpoint}>
                <div className={styles.Network__endpoint}>
                    <div className={styles.endpoint__text}>{getPreparedEndpoint(endpoint)}</div>
                    <div className={styles.endpoint__copyWrapper}>
                        <Copy key="copy" value={endpoint} size="sm" />
                    </div>
                </div>
                <div className={styles.Network__endpoint}>
                    <div className={styles.endpoint__text}>
                        {getPreparedEndpoint(endpoint, true)}
                    </div>
                    <div className={styles.endpoint__copyWrapper}>
                        <Copy key="copy" value={endpoint.replace("https://", "wss://")} size="sm" />
                    </div>
                </div>
            </div>
        ))}
    </div>
);

const NetworkTitle: React.FC<NetworkProps> = ({ project, network, setTopupNetwork }) => {
    const loc = useLoc().projectSummary;

    const networkName = network.network.name;

    return (
        <>
            <div className={styles.NetworkTitle}>
                <img
                    alt="network"
                    src={
                        network.network.logo
                            ? network.network.logo
                            : networkName.toLowerCase().includes(TONNetworkName)
                                ? "/toncoin-ton-seeklogo.svg"
                                : "/everscale-seeklogo.svg"
                    }
                    className={styles.NetworkTitle__networkIcon}
                />
                <div className={styles.NetworkTitle__text}>{networkName}</div>
                <Tooltip title={loc.playground}>
                    <div>
                        <PlaygroundLink
                            key="play"
                            project={project}
                            endpoint={network.endpoints[0]}
                        />
                    </div>
                </Tooltip>
                {network.network.topupEnabled ? (
                    <Tooltip title={loc.tokenFaucet}>
                        <Button
                            size="sm"
                            variant="outline-primary"
                            className={styles.NetworkTitle__giverButton}
                            onClick={setTopupNetwork}
                        >
                            <div className={styles.NetworkTitle__giverButton_wrapper}>
                                <img alt="giver" src="/faucet.svg" />
                            </div>
                        </Button>
                    </Tooltip>
                ) : null}
            </div>
        </>
    );
};

interface IPlaygroundLinkProps {
    project: CloudProject;
    endpoint: string;
};

export const PlaygroundLink: React.FC<IPlaygroundLinkProps> = ({ endpoint }) => {
    const loc = useLoc().projectSummary;
    return (
        <a href={endpoint} target="_blank" rel="noreferrer" className={styles.PlaygroundLink}>
            <img src="/graphql-playground.png" alt={loc.playground} />
        </a>
    );
};
