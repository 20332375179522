import { useNavigate } from "react-router-dom";
import { CloudProject } from "cloud";
import { Nav } from "react-bootstrap";
import { ProjectSettings } from "screens/dashboard/project/ProjectSettings";
import { ProjectEndpoints } from "screens/dashboard/project/ProjectEndpoints";
import { ProjectSecurity } from "screens/dashboard/project/ProjectSecurity";
import { ProjectAnalytics } from "screens/dashboard/project/ProjectAnalytics";
import { NAV, ProjectSection, useLoc } from "config";
import classNames from "classnames";

import commonStyles from "styles/comon.module.scss";
import styles from "./ProjectDetails.module.scss";

export type ProjectDetailsProps = {
    project: CloudProject;
    section: ProjectSection;
};

export function ProjectDetails({ project, section }: ProjectDetailsProps) {
    const loc = useLoc().dashboard;
    const navigate = useNavigate();

    const details = () => {
        switch (section) {
            case ProjectSection.endpoints:
                return <ProjectEndpoints project={project} />;
            case ProjectSection.analytics:
                return <ProjectAnalytics project={project} />;
            case ProjectSection.security:
                return <ProjectSecurity project={project} />;
            case ProjectSection.settings:
                return <ProjectSettings project={project} />;
        }
    };

    const tab = (tabSection: ProjectSection, tabTitle: string) => (
        <Nav.Item>
            <Nav.Link
                className={section === tabSection ? "ec-tab-selected" : "ec-tab-normal"}
                eventKey={tabSection}
            >
                {tabTitle}
            </Nav.Link>
        </Nav.Item>
    );

    return (
        <div className={commonStyles.ecCard}>
            <Nav
                className={classNames(styles.ProjectDetails__nav, "m-3 mb-2 mt-0")}
                variant="pills"
                defaultActiveKey={section}
                onSelect={section => navigate(NAV.project(project.id, section as ProjectSection))}
            >
                {tab(ProjectSection.endpoints, loc.endpoints)}
                {tab(ProjectSection.analytics, loc.analytics)}
                {tab(ProjectSection.security, loc.security)}
                {tab(ProjectSection.settings, loc.settings)}
            </Nav>
            {details()}
        </div>
    );
}
