import { Form, Stack, Toast, ToastContainer } from "react-bootstrap";
import { Copy, PropList } from "components";
import { CloudProject, useCloud } from "cloud";
import { useLoc } from "config";
import { useEffect, useState } from "react";
import { asyncGuard, asyncWork, basicAuth } from "utils";

type ProjectSecurityProps = {
    project: CloudProject;
};

export function ProjectSecurity(props: ProjectSecurityProps) {
    const project = props.project;
    const cloud = useCloud();
    const loc = useLoc().projectSummary;
    const originalSecretRequired = project?.secretRequired ?? false;
    const [secretRequired, setSecretRequired] = useState(originalSecretRequired);
    const [updating, setUpdating] = useState(asyncGuard());
    const [updated, setUpdated] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setSecretRequired(project?.secretRequired ?? false);
    }, [project]);

    const onChangeSecretRequired = (event: any) => {
        asyncWork(
            updating,
            async () => {
                await cloud.saveProject({
                    id: project?.id ?? "",
                    secretRequired: event.target.checked,
                });
                setUpdated(true);
            },
            setUpdating,
            setError,
        )();
    };

    const secretAuth = basicAuth(project.secret);
    const summaryProps = [
        {
            key: "id",
            title: loc.id,
            value: project.id,
            actions: [<Copy value={project.id} size="sm" />],
        },
        {
            key: "secret",
            title: loc.secret,
            value: project.secret,
            actions: [<Copy value={project.secret} size="sm" />],
        },
        {
            key: "secret_auth",
            title: loc.secretAuth,
            value: secretAuth,
            actions: [<Copy value={secretAuth} size="sm" />],
        },
        {
            key: "secret_required",
            title: loc.secretRequired,
            value: (
                <Form.Check
                    id="secretRequired"
                    type="switch"
                    checked={secretRequired}
                    disabled={updating.processing}
                    onChange={onChangeSecretRequired}
                />
            ),
        },
    ];
    return (
        <Stack direction="vertical" gap={4}>
            <div className="ec-card p-4">
                <div className="ec-card-body">
                    <PropList props={summaryProps} />
                </div>
            </div>
            <ToastContainer>
                <Toast show={!!error} bg="danger">
                    <Toast.Body>{error}</Toast.Body>
                </Toast>
                <Toast
                    show={updated}
                    delay={2000}
                    autohide
                    onClose={() => setUpdated(false)}
                    bg="success"
                >
                    <Toast.Body className="text-white">{loc.updated}</Toast.Body>
                </Toast>
            </ToastContainer>
        </Stack>
    );
}
