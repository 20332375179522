import { LIGHT_STYLES } from "./lightStyles";
import { ThemeStyles } from "./theme";

function overrideStyles(source: ThemeStyles, overrides: any): ThemeStyles {
    return {
        ...source,
        ...overrides,
    };
}

export const DARK_STYLES: ThemeStyles = overrideStyles(LIGHT_STYLES, {
    accountSideBar: {
        sideBar: {
            background: "MediumSlateBlue",
        },
        title: {
            color: "Snow",
        },
    },
});
