import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { NAV, useLoc } from "config";
import { useCloud } from "cloud";
import { asyncSubmit, passwordHash } from "utils";
import buffer from "buffer";
import { FormikErrors, FormikHelpers, useFormik } from "formik";
import { FormAlert, FormField } from "components";

interface Values {
    password: string;
    confirmPassword: string;
}

function decodeResetCode(code: string): { code: string; email: string } {
    const parts = buffer.Buffer.from(code ?? "", "hex").toString("utf8");
    const spacePos = parts.indexOf(" ");
    return {
        code: parts.substring(0, spacePos),
        email: parts.substring(spacePos + 1),
    };
}

export function PasswordReset() {
    const cloud = useCloud();
    const navigate = useNavigate();

    const [error, setError] = useState<string | undefined>();

    const loc = useLoc();
    const { code } = useParams<{ code: string }>();

    const formik = useFormik<Values>({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        onSubmit: async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
            const reset = decodeResetCode(code ?? "");
            await asyncSubmit(
                async () => {
                    await cloud.resetPassword(
                        code ?? "",
                        passwordHash(reset.email, values.password),
                    );
                    navigate(NAV.projects);
                },
                setSubmitting,
                setError,
            )();
        },
        validate: ({ password, confirmPassword }: Values) => {
            const errors: FormikErrors<Values> = {};
            if (password === "") {
                errors.password = loc.validation.required;
            }
            if (password !== confirmPassword) {
                errors.confirmPassword = loc.validation.confirmPasswordMismatch;
            }
            return errors;
        },
    });
    return (
        <div className="ec-centered-card p-4">
            <div className="m-3">{loc.resetPassword.title}</div>
            <div>
                <Form className="reset-password-form" onSubmit={formik.handleSubmit}>
                    <FormField
                        type="password"
                        field="password"
                        label={loc.resetPassword.password}
                        formik={formik}
                    />
                    <FormField
                        type="password"
                        field="confirmPassword"
                        label={loc.resetPassword.confirmPassword}
                        formik={formik}
                    />
                    <Button type="submit" variant="primary" disabled={formik.isSubmitting}>
                        {loc.resetPassword.reset}
                    </Button>
                </Form>
                <FormAlert error={error} setError={setError} />
            </div>
        </div>
    );
}
