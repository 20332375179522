import { EN_LOC } from "./enLoc";

export type LangLoc = typeof EN_LOC;

export enum Lang {
    en = "en",
}

export function getLoc(lang: Lang): LangLoc {
    switch (lang) {
        case Lang.en:
            return EN_LOC;
    }
}
