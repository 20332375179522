export enum ProjectSection {
    endpoints = "endpoints",
    analytics = "analytics",
    security = "security",
    settings = "settings",
}

export enum AccountSection {
    summary = "summary",
    usage = "usage",
    settings = "settings",
}

export const NAV = {
    root: "/",
    login: "/login",
    account: "/account",
    projects: "/projects",
    register: "/register",
    verification: "/verification",
    passwordReset: "/reset-password",
    forgotPassword: "/forgot-password",
    explorer: "/explorer",
    usage: "/usage",
    docs: "https://docs.evercloud.dev/",
    featureRequest: "http://feedback.evercloud.dev/",

    project(id: string, section?: ProjectSection | null) {
        return `${NAV.projects}/${id}/${section ?? ProjectSection.settings}`;
    },
    accountDetails(section?: AccountSection | null) {
        return `${NAV.account}/${section ?? AccountSection.summary}`;
    },
};
