import { styled } from "@mui/material/styles";
import {
    Accordion as MuiAccordion,
    AccordionProps,
    FormControlLabel as MuiFormControlLabel,
    FormControlLabelProps, Radio as MuiRadio, RadioProps
} from "@mui/material";
import React from "react";

import styles from "../Filters.module.scss";

export const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    margin: 0,
}));

export  const FormControlLabel = styled((props: FormControlLabelProps) => (
    <MuiFormControlLabel {...props} className={styles.Filters__label} />
))(({ theme }) => ({
    '.MuiTypography-root': {
        fontSize: 15,
    }
}));

export const Radio = styled((props: RadioProps) => (
    <MuiRadio {...props} classes={{
        checked: styles.Filters__radio__checked,
        root: styles.Filters__radio,
    }} />
))(({ theme }) => ({
    padding: 5,
}));
