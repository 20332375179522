import * as React from "react";
import classNames from "classnames";
import { AccountSideMenu } from "screens/dashboard/account/AccountSideMenu";
import { ProjectList } from "screens/dashboard/project/ProjectList";
import { CloudAccount } from "cloud";
import { AccountSection, ProjectSection, useLoc } from "config";

import commonStyles from "styles/comon.module.scss";
import dashboardSidebarStyles from "./DashboardSidebar.module.scss";
const styles: any = dashboardSidebarStyles;

type DashboardSidebarProps = {
    account: CloudAccount;
    selectedProjectId?: string;
    projectSection?: ProjectSection;
    accountSection?: AccountSection;
};

export function DashboardSidebar(props: React.HTMLAttributes<HTMLElement> & DashboardSidebarProps) {
    const account = props.account;
    const selectedProjectId = props.selectedProjectId;
    const projectSection = props.projectSection ?? ProjectSection.endpoints;
    return (
        <aside className={classNames(styles.DashboardSidebar, commonStyles.ecCard)}>
            <AccountSideMenu section={props.accountSection} isProjectSelected={selectedProjectId} />
            {account.isVerified ? (
                <ProjectList
                    projects={account.projects}
                    selected={selectedProjectId}
                    section={projectSection}
                />
            ) : null}
            <UsefulLinks />
        </aside>
    );
}

export function UsefulLinks(props: React.HTMLAttributes<HTMLElement>) {
    const loc = useLoc().accountSidebar;
    return (
        <div {...props}>
            <div className="ec-card-header m-3 mt-0 mb-3">{loc.links}</div>
            <div className="p-3 pt-2 pb-2">
                <a
                    className="ec-card-link p-0"
                    href="https://docs.everos.dev/evernode-platform/products/evercloud/get-started"
                    target="_blank"
                    rel="noreferrer"
                >
                    {loc.gettingStarted}
                </a>
            </div>
            <div className="p-3 pt-2 pb-2">
                <a
                    className="ec-card-link p-0"
                    href="https://docs.evercloud.dev/products/evercloud/get-started#connect-to-evercloud"
                    target="_blank"
                    rel="noreferrer"
                >
                    {loc.howToConnect}
                </a>
            </div>
        </div>
    );
}
