import { useState } from "react";
import { Link } from "react-router-dom";
import { asyncSubmit } from "utils";
import { Button, Card, Form } from "react-bootstrap";
import { NAV, useLoc } from "config";
import { useCloud } from "cloud";
import { FormikErrors, FormikHelpers, useFormik } from "formik";
import { FormAlert, FormField } from "components";

interface Values {
    email: string;
}

export function ForgotPassword() {
    const loc = useLoc();
    const cloud = useCloud();

    const [error, setError] = useState<string | undefined>();
    const [sent, setSent] = useState(false);
    const formik = useFormik<Values>({
        initialValues: {
            email: "",
        },
        onSubmit: async ({ email }: Values, { setSubmitting }: FormikHelpers<Values>) => {
            await asyncSubmit(
                async () => {
                    await cloud.requestResetPassword(email);
                    setSent(true);
                },
                setSubmitting,
                setError,
            )();
        },
        validate: ({ email }: Values) => {
            const errors: FormikErrors<Values> = {};
            if (email === "") {
                errors.email = loc.validation.required;
            }
            return errors;
        },
    });

    return (
        <div className="ec-centered-card p-4" style={{ maxWidth: "30em" }}>
            {sent ? (
                <>
                    <div className="ec-card-body">
                        <Card.Text>
                            {loc.forgotPassword.sent.replace("{email}", formik.values.email)}
                        </Card.Text>
                    </div>
                </>
            ) : (
                <>
                    <div className="ec-card-header mb-3">{loc.forgotPassword.title}</div>
                    <div className="ec-card-body">
                        <Card.Text>{loc.forgotPassword.info}</Card.Text>
                        <Form onSubmit={formik.handleSubmit}>
                            <FormField
                                type="email"
                                field="email"
                                label={loc.forgotPassword.email}
                                formik={formik}
                            />
                            <div className="d-grid">
                                <Button
                                    type="submit"
                                    size="lg"
                                    variant="primary"
                                    disabled={formik.isSubmitting}
                                >
                                    {loc.resetPassword.reset}
                                </Button>
                            </div>
                        </Form>
                        <FormAlert error={error} setError={setError} />
                    </div>
                </>
            )}
            <div className="ec-text-center text-center mt-4">
                <Link className="ec-card-link" to={NAV.login}>
                    {loc.forgotPassword.backToLogin}
                </Link>
            </div>
        </div>
    );
}
