import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { NAV } from "config";
import { AppHeader } from "components/AppHeader";
import {
    Landing,
    Login,
    Register,
    Dashboard,
    Verification,
    PasswordReset,
    ForgotPassword,
    Explorer,
} from "screens";

import "./App.css";
import {useState} from "react";
import {SelectedProjectIdContext} from "./cloud";

const metaTag = document.getElementsByName("project-version")[0];
// @ts-ignore
metaTag.content = process.env.REACT_APP_VERSION;

function App() {
    const [projectId, setProjectId] = useState(undefined);

    return (
        <SelectedProjectIdContext.Provider value={{
            projectId,
            setProjectId,
        }}>
            <BrowserRouter>
                <AppHeader />
                <Routes>
                    <Route path={NAV.root} element={<Landing />} />
                    <Route path={NAV.login} element={<Login />} />
                    <Route path={NAV.register} element={<Register />} />
                    <Route path={NAV.projects} element={<Dashboard />} />
                    <Route path={NAV.verification}>
                        <Route path=":code" element={<Verification />} />
                    </Route>
                    <Route path={NAV.passwordReset}>
                        <Route path=":code" element={<PasswordReset />} />
                    </Route>
                    <Route path={NAV.forgotPassword} element={<ForgotPassword />} />
                    <Route path={NAV.projects}>
                        <Route path=":projectId">
                            <Route path=":projectSection" element={<Dashboard />} />
                        </Route>
                    </Route>
                    <Route path={NAV.account}>
                        <Route path=":accountSection">
                            <Route path=":projectId" element={<Dashboard />} />
                        </Route>
                    </Route>
                    <Route path={NAV.explorer} element={<Explorer />} />
                </Routes>
            </BrowserRouter>
        </SelectedProjectIdContext.Provider>
    );
}

export default App;
