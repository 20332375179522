import { Button, Stack } from "react-bootstrap";
import { useLoc } from "config";
import { CloudAccount } from "cloud";
import { PropList } from "components";

export type AccountSummaryProps = {
    account: CloudAccount;
};

export function AccountSummary({ account }: AccountSummaryProps) {
    const loc = useLoc().accountSummary;
    return (
        <Stack gap={3} className="ec-card p-4">
            <div className="ec-card-body">
                <PropList
                    valueAlignment="right"
                    props={[
                        {
                            key: "plan",
                            title: loc.plan,
                            value: (
                                <table style={{ marginLeft: "12pt" }}>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td
                                                style={{
                                                    paddingLeft: "8pt",
                                                }}
                                            >
                                                {account.plan.name}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ),
                        },
                        {
                            key: "balance",
                            title: loc.balance,
                            value: (
                                <table style={{ marginLeft: "8pt" }}>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{
                                                    paddingLeft: "8pt",
                                                    fontSize: "x-large",
                                                    color: "orange",
                                                }}
                                            >
                                                ${account.balance ?? 0}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ),
                        },
                    ]}
                />
            </div>
            <div className="ec-card-footer">
                <Stack direction="horizontal" gap={2}>
                    <Button disabled={true} variant="outline-secondary" className="ec-card-topup">
                        {loc.topup}
                    </Button>
                    <Button disabled={true} variant="outline-secondary" className="ec-card-upgrade">
                        {loc.upgrade}
                    </Button>
                </Stack>
            </div>
        </Stack>
    );
}
