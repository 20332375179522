import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NAV } from "config";
import { useCloud } from "cloud";

export function Landing() {
    const cloud = useCloud();
    const navigate = useNavigate();
    const [isAuth, setIsAuth] = useState(cloud.isAuth);
    const [account, setAccount] = useState(cloud.account);

    useEffect(() => {
        if (isAuth) {
            if (account) {
                navigate(NAV.projects);
            }
        } else {
            navigate(NAV.login);
        }
        const handler = cloud.on({
            account(acc) {
                setAccount(acc);
            },
            auth(isAuth) {
                setIsAuth(isAuth);
            },
        });
        return () => cloud.remove(handler);
    }, [cloud, navigate, isAuth, account]);

    return <p className="mb-3 m-lg-3">Loading...</p>;
}
