import React from "react";
import {
    AccordionDetails,
    AccordionSummary,
    RadioGroup,
    TextField
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import type { ProjectLogFilters } from "../../types";
import { Status, TimeRange } from "../../types";
import { TimeRangePicker } from "./components/TimeRangePicker";

import styles from "./Filters.module.scss";
import { useCloud } from "../../../../cloud";
import { Accordion, FormControlLabel, Radio } from "./components";
import { Button } from "react-bootstrap";
import { useLoc } from "../../../../config";

type FiltersProps = {
    filters: ProjectLogFilters;
    onChange: (v: ProjectLogFilters) => void;
    isLoading: boolean;
};

const Filters: React.FC<FiltersProps> = ({ filters, onChange, isLoading }) => {
    const cloud = useCloud();
    const loc = useLoc().app;

    return (
        <aside className={styles.Filters}>
            <Accordion className={styles.Filters__section} defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    classes={{
                        root: styles.section__header,
                        content: styles.section__content,
                    }}
                >
                    PROJECT
                </AccordionSummary>
                <AccordionDetails>
                    <RadioGroup
                        value={filters.projectId}
                        onChange={e => onChange({ ...filters, projectId: e.target.value })}
                    >
                        {(cloud.account?.projects || []).map(project => (
                            <FormControlLabel
                                key={project.id}
                                control={<Radio size="small" value={project.id} checked={filters.projectId === project.id} />}
                                label={project.name}
                            />
                        ))}
                        <FormControlLabel control={<Radio size="small" value={undefined} checked={!filters.projectId} />} label="All" />
                    </RadioGroup>
                </AccordionDetails>
            </Accordion>
            <Accordion className={styles.Filters__section} defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    classes={{
                        root: styles.section__header,
                        content: styles.section__content,
                    }}
                >
                    Time
                </AccordionSummary>
                <AccordionDetails >
                    <RadioGroup
                        value={filters.timeRange}
                        onChange={e =>
                            onChange({ ...filters, timeRange: e.target.value as TimeRange })
                        }
                    >
                        <FormControlLabel
                            control={
                                <Radio
                                    size="small"
                                    checked={filters.timeRange === TimeRange.Last5Min}
                                    value={TimeRange.Last5Min}
                                />
                            }
                            label="Last 5 minutes"
                        />
                        <FormControlLabel
                            control={
                                <Radio
                                    size="small"
                                    value={TimeRange.LastHour}
                                    checked={filters.timeRange === TimeRange.LastHour}
                                />
                            }
                            label="Last hour"
                        />
                        <FormControlLabel
                            control={
                                <Radio
                                    size="small"
                                    value={TimeRange.LastDay}
                                    checked={filters.timeRange === TimeRange.LastDay}
                                />
                            }
                            label="Last day"
                        />
                        <FormControlLabel
                            control={
                                <Radio
                                    size="small"
                                    value={TimeRange.Last7Days}
                                    checked={filters.timeRange === TimeRange.Last7Days}
                                />
                            }
                            label="Last 7 days"
                        />
                        <TimeRangePicker
                            timeRange={filters.timeRange}
                            timestampFrom={filters.timestampFrom}
                            timestampTo={filters.timestampTo}
                            onChange={v => onChange({ ...filters, ...v })}
                        />
                    </RadioGroup>
                </AccordionDetails>
            </Accordion>
            <Accordion className={styles.Filters__section} defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    classes={{
                        root: styles.section__header,
                        content: styles.section__content,
                    }}
                >
                    Query substring
                </AccordionSummary>
                <AccordionDetails>
                    <TextField
                        size="small"
                        value={filters.search}
                        className={styles.Filters__field}
                        onChange={e => onChange({ ...filters, search: e.target.value })}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion className={styles.Filters__section} defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    classes={{
                        root: styles.section__header,
                        content: styles.section__content,
                    }}
                >
                    Status
                </AccordionSummary>
                <AccordionDetails>
                    <RadioGroup
                        value={filters.errorsOnly}
                        onChange={e =>
                            onChange({
                                ...filters,
                                errorsOnly: e.target.value === Status.ErrorsOnly,
                            })
                        }
                    >
                        <FormControlLabel control={<Radio size="small" value={Status.All} checked={!filters.errorsOnly} />} label="All" />
                        <FormControlLabel
                            control={<Radio size="small" value={Status.ErrorsOnly} checked={filters.errorsOnly} />}
                            label="Errors only"
                        />
                    </RadioGroup>
                </AccordionDetails>
            </Accordion>
            <Accordion className={styles.Filters__section} defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    classes={{
                        root: styles.section__header,
                        content: styles.section__content,
                    }}
                >
                    Response time
                </AccordionSummary>
                <AccordionDetails>
                    <div className={styles.section__duraiton}>
                        <TextField
                            className={styles.Filters__field}
                            size="small"
                            value={filters.resTimeMin || ""}
                            placeholder="Min"
                            onChange={e =>
                                onChange({
                                    ...filters,
                                    resTimeMin: Number(e.target.value) || undefined,
                                })
                            }
                        />
                        <TextField
                            className={styles.Filters__field}
                            size="small"
                            value={filters.resTimeMax || ""}
                            placeholder="Max"
                            onChange={e =>
                                onChange({
                                    ...filters,
                                    resTimeMax: Number(e.target.value) || undefined,
                                })
                            }
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
            <Button disabled={isLoading} style={{ width: "100%" }} onClick={() => onChange({ ...filters })}>
                {loc.refresh}
            </Button>
        </aside>
    );
};

export { Filters };
