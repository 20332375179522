import { useState } from "react";
import { useCloud } from "cloud";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useLoc } from "config";
import { FormField, FormAlert } from "components";
import { FormikErrors, useFormik } from "formik";
import { asyncSubmit } from "utils";

interface Values {
    question: number;
    answer: string | undefined;
}

export function Feedback(props: { questionIndex?: number, inputWidth?: string | number }) {
    const { questionIndex, inputWidth } = props;
    const loc = useLoc();
    const cloud = useCloud();
    const [sent, setSent] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const formik = useFormik<Values>({
        initialValues: {
            question: questionIndex !== undefined ? questionIndex : Math.trunc(Math.random() * 3),
            answer: "",
        },
        validate: ({ answer }: Values) => {
            const errors: FormikErrors<Values> = {};
            if (answer === "") {
                errors.answer = loc.validation.required;
            }
            return errors;
        },
        onSubmit: async ({ question, answer }, { setSubmitting }) => {
            await asyncSubmit(
                async () => {
                    await cloud.submitFeedback(loc.feedback.questions[question][0], answer ?? "");
                    setSent(true);
                    formik.resetForm({
                        values: {
                            question,
                            answer: "",
                        },
                    });
                },
                setSubmitting,
                setError,
            )();
        },
    });

    const question = loc.feedback.questions[formik.values.question];

    return (
        <div className="ec-card p-4 pb-2">
            {sent ? (
                <div className="mb-3">{loc.feedback.sent}</div>
            ) : (
                <>
                    <Form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col className="ec-card-body mb-3">{question[1]}</Col>
                        </Row>
                        <Row className="gx-3 ec-card-body">
                            <Col className="col-auto" style={{ width: inputWidth ? inputWidth : "30em" }}>
                                <FormField<Values>
                                    field="answer"
                                    type="text"
                                    label={loc.feedback.answer}
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <Button
                                    type="submit"
                                    style={{ height: "3.6em" }}
                                    variant="primary"
                                    disabled={!formik.dirty || formik.isSubmitting}
                                >
                                    {loc.feedback.submit}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <FormAlert error={error} setError={setError} />
                </>
            )}
        </div>
    );
}
