import { CloudProject, useCloud } from "cloud";
import { noValuePlaceholder } from "../../explorer/constants";
import React, { useEffect, useState } from "react";

import { ProjectSummaryType } from "../../explorer/types";

import projectAnalyticsStyles from "./ProjectAnalytics.module.scss";
const styles: any = projectAnalyticsStyles;

type ProjectAnalyticsProps = {
    project: CloudProject;
};

export function ProjectAnalytics(props: ProjectAnalyticsProps) {
    const project = props.project;
    const cloud = useCloud();
    const [summary, setSummary] = useState<ProjectSummaryType | null>(null);

    useEffect(() => {
        cloud
            .getProjectSummary(project.id)
            .then(res => {
                if (res?.analytics?.projectSummary) {
                    setSummary(res.analytics.projectSummary);
                } else {
                    setSummary(null);
                }
            })
            .catch(e => {
                setSummary(null);
            });
    }, [project]);

    return (
        <div className="ec-card p-4">
            <div className="ec-card-body">
                <section className={styles.Project__summary}>
                    <div className={styles.summary__card}>
                        <p className={styles.card__title}>
                            Compute units per second over last 5 minutes
                        </p>
                        <p className={styles.card__value}>
                            {summary?.pointsPerSec5M ?? noValuePlaceholder}
                        </p>
                    </div>
                    <div className={styles.summary__card}>
                        <p className={styles.card__title}>
                            Median response time (over last 5 mins)
                        </p>
                        <p className={styles.card__value}>
                            {summary?.medianResTime5M ?? noValuePlaceholder}
                        </p>
                    </div>
                    <div className={styles.summary__card}>
                        <p className={styles.card__title}>Success rate (last 1 H)</p>
                        <p className={styles.card__value}>
                            {summary?.successRate1H ?? noValuePlaceholder}
                        </p>
                    </div>
                    <div className={styles.summary__card}>
                        <p className={styles.card__title}>Total requests ( last 1 H)</p>
                        <p className={styles.card__value}>
                            {summary?.totalRequests1H ?? noValuePlaceholder}
                        </p>
                    </div>
                </section>
            </div>
        </div>
    );
}
