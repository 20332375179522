import { CloudAccount } from "cloud";

import { useEffect, useState } from "react";

import { Grid } from "@mui/material";

import { useLoc } from "config";
import { useCloud } from "cloud";

import styles from "./AccountUsage.module.scss";

export type AccountUsageProps = {
    account: CloudAccount;
};

export function AccountUsage(_props: AccountUsageProps) {
    const cloud = useCloud();
    const loc = useLoc();

    const [accountSummary, setAccountSummary] = useState<number | null>(null);
    const currentMonth = new Date().toLocaleString("en-US", { month: "long" });
    const localizedCurrentMonth = loc.date.months[currentMonth as keyof typeof loc.date.months];

    useEffect(() => {
        const currDate = new Date();
        const from = Date.UTC(currDate.getFullYear(), currDate.getMonth(), 1, 0, 0, 0, 0);
        cloud
            .getAccountSummary(Math.floor(from / 1000), Math.floor(Date.now() / 1000))
            .then(res => {
                console.log(res);
                if (res?.analytics?.accountSummary) {
                    setAccountSummary(res.analytics.accountSummary.points);
                }
            });
    }, []);

    const formattedSummary = (accountSummary || 0).toLocaleString();

    return (
        <div className="ec-card p-4">
            <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
                <div className={styles.Usage}>
                    <Grid
                        spacing={0.5}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={10} sm={5} md={10} className={styles.Usage__title}>
                            {localizedCurrentMonth} {loc.accountUsage.summary}
                        </Grid>
                        <Grid item xs={5}>
                            <div className={styles.Usage__banner}>
                                <div className={styles.Usage__banner__currentMonth}>
                                    {localizedCurrentMonth} {loc.accountUsage.title}
                                </div>
                                <div>
                                    <div className={styles.Usage__banner__amountValue}>
                                        {formattedSummary}
                                    </div>
                                    <div className={styles.Usage__banner__label}>
                                        Total compute units used
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={5}></Grid>
                    </Grid>
                </div>
            </Grid>
        </div>
    );
}
