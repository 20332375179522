import { FloatingLabel, Form } from "react-bootstrap";
import React from "react";
import { useLoc } from "../config";
import { FormikErrors, FormikTouched } from "formik";

export type PropSize = "sm" | "md" | "lg";

interface IFormik<Values> {
    isSubmitting: boolean;
    values: Values;
    errors: FormikErrors<Values>;
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
            ? void
            : (e: string | React.ChangeEvent<any>) => void;
    };
    touched: FormikTouched<Values>;
}

type FormFieldProps<Values> = {
    type: string;
    field: keyof Values;
    label: string;
    formik: IFormik<Values>;
    required?: boolean;
};

export function FormField<Values>(
    props: FormFieldProps<Values> & React.HTMLAttributes<HTMLElement>,
) {
    const { field, formik, label } = props;
    const required = props.required ?? false;
    const error = (formik.errors as any)[field];
    return (
        <FloatingLabel label={required ? `${label} *` : label} className="mb-3 ec-form-field">
            <Form.Control
                id={String(field)}
                type={props.type}
                value={(formik.values as any)[field]}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={props.label}
                isInvalid={(formik.touched as any)[field] && !!error}
            />
            <Form.Control.Feedback type="invalid">{`${error}`}</Form.Control.Feedback>
        </FloatingLabel>
    );
}

type FormSelectProps<Values> = {
    id: keyof Values;
    label: string;
    options: string[][];
    formik: IFormik<Values>;
    required?: boolean;
};

export function FormSelect<Values>(props: FormSelectProps<Values>) {
    const { id, label, options, formik } = props;
    const required = props.required ?? false;
    const error = (formik.errors as any)[id];
    return (
        <FloatingLabel label={required ? `${label} *` : label} className="mb-3 ec-form-field">
            <Form.Select
                id={String(id)}
                value={(formik.values as any)[id]}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={(formik.touched as any)[id] && !!error}
            >
                <option value="" key="none">
                    {useLoc().accountProfile.select}
                </option>
                {options.map(option => (
                    <option key={option[0]} value={option[0]}>
                        {option[1]}
                    </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{`${error}`}</Form.Control.Feedback>
        </FloatingLabel>
    );
}
