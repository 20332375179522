import { recolor } from "../utils";
// const baseColor = "mediumslateblue";
const baseColor = "gray";
export const LIGHT_STYLES = {
    login: {
        container: {
            background: recolor(0.9, baseColor),
            border: `1px solid ${recolor(0.8, baseColor)}`,
            boxShadow: `1px 1px 2px ${recolor(0.4, baseColor)}`,
        },
    },
    register: {
        container: {
            padding: "8pt",
            background: recolor(0.9, baseColor),
            border: `1px solid ${recolor(0.8, baseColor)}`,
            boxShadow: `1px 1px 2px ${recolor(0.4, baseColor)}`,
        },
    },
    sideBar: {
        container: {
            background: recolor(0.85, baseColor),
        },
    },
    dashboard: {
        isNotVerified: {
            color: "Tomato",
        },
        details: {
            padding: "6pt 16pt 16pt 16pt",
            background: recolor(0.95, baseColor),
            borderTop: `1px solid ${recolor(0.8, baseColor)}`,
            borderBottomRightRadius: "12pt",
            borderBottomLeftRadius: "12pt",
        },
    },
    resetPassword: {
        container: {
            padding: "8pt",
            background: recolor(0.9, baseColor),
            border: `1px solid ${recolor(0.8, baseColor)}`,
            boxShadow: `1px 1px 2px ${recolor(0.4, baseColor)}`,
        },
    },
    centered: {
        maxWidth: "24rem",
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translateX(-50%) translateY(-50%)",
    },
};
