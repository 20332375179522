import { useState } from "react";
import { CloudAccount, useCloud } from "cloud";
import { Button, Form, Spinner, Stack } from "react-bootstrap";
import { useLoc } from "config";
import { FormField, FormAlert } from "components";
import { FormikErrors, useFormik } from "formik";
import { FormSelect } from "components/FormField";
import { asyncSubmit } from "utils";

type AccountProfileProps = {
    account: CloudAccount;
};

interface Values {
    organizationName: string;
    organizationUrl: string;
    organizationSize: string;
    organizationCategory: string;
    userRole: string;
}

export function AccountProfile({ account }: AccountProfileProps) {
    const loc = useLoc();
    const cloud = useCloud();
    const [error, setError] = useState<string | undefined>();
    const formik = useFormik<Values>({
        initialValues: {
            organizationName: account.organizationName ?? "",
            organizationUrl: account.organizationUrl ?? "",
            organizationSize: account.organizationSize ?? "",
            organizationCategory: account.organizationCategory ?? "",
            userRole: account.userRole ?? "",
        },
        validate: ({
            organizationName,
            organizationSize,
            organizationCategory,
            userRole,
        }: Values) => {
            const errors: FormikErrors<Values> = {};
            if (organizationName === "") {
                errors.organizationName = loc.validation.required;
            }
            if (organizationSize === "") {
                errors.organizationSize = loc.validation.required;
            }
            if (organizationCategory === "") {
                errors.organizationCategory = loc.validation.required;
            }
            if (userRole === "") {
                errors.userRole = loc.validation.required;
            }
            return errors;
        },
        onSubmit: async (values, { setSubmitting }) => {
            await asyncSubmit(
                async () => {
                    await cloud.updateProfile(values);
                },
                setSubmitting,
                setError,
            )();
        },
    });

    return (
        <div className="ec-card p-4">
            <Form onSubmit={formik.handleSubmit}>
                <div className="ec-card-body" style={{ maxWidth: "500px" }}>
                    {loc.accountProfile.description}
                </div>
                <div className="ec-card-body">
                    <FormField
                        field="organizationName"
                        type="text"
                        label={loc.accountProfile.organizationName}
                        formik={formik}
                        required
                    />
                    <FormField
                        field="organizationUrl"
                        type="text"
                        label={loc.accountProfile.organizationUrl}
                        formik={formik}
                    />
                    <FormSelect<Values>
                        id="userRole"
                        label={loc.accountProfile.userRole}
                        options={loc.accountProfile.userRoles}
                        formik={formik}
                    />
                    <FormSelect<Values>
                        id="organizationSize"
                        label={loc.accountProfile.organizationSize}
                        options={loc.accountProfile.organizationSizes}
                        formik={formik}
                    />
                    <FormSelect<Values>
                        id="organizationCategory"
                        label={loc.accountProfile.organizationCategory}
                        options={loc.accountProfile.organizationCategories}
                        formik={formik}
                        required
                    />
                </div>
                <Form.Group>
                    <Stack
                        direction="horizontal"
                        gap={3}
                        className="ec-card-footer"
                        style={{
                            marginLeft: 0,
                            paddingTop: "8pt",
                            marginTop: "12pt",
                            width: "100%",
                        }}
                    >
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={!formik.dirty || formik.isSubmitting}
                        >
                            {formik.isSubmitting ? (
                                <span style={{ paddingRight: "4pt" }}>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </span>
                            ) : null}
                            <span>{loc.accountProfile.save}</span>
                        </Button>
                    </Stack>
                </Form.Group>
            </Form>
            <FormAlert error={error} setError={setError} />
        </div>
    );
}
