import React, {useCallback, useEffect, useRef, useState} from "react";
import classnames from "classnames";

import { ProjectLogRecord } from "../../types";

import styles from "./LogsBody.module.scss";
import {
    CircularProgress,
    Pagination,
    Backdrop as MuiBackdrop,
    BackdropProps
} from "@mui/material";
import moment from 'moment/moment';
import { styled } from "@mui/material/styles";

type LogsBodyProps = {
    logs: ProjectLogRecord[] | null;
    isLoading: boolean;
};

type RowElementProps = { className?: string, children: React.ReactNode, onHover: () => void };
type RowProps = ProjectLogRecord & { index: number, onHover: () => void } ;

export const Backdrop = styled((props: BackdropProps) => (
    <MuiBackdrop {...props} />
))(({ theme }) => ({
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: 'rgba(0,0,0,0.1)'
}));

const maxNumberOfPageElements = 25;

const RowElement: React.FC<RowElementProps> = props => {
    const [isHover, setHover] = useState(false);
    useEffect(() => {
        if (isHover) {
            props.onHover();
        }
    }, [isHover])

    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={classnames(props.className, styles.row__element )}>
            <p className={styles.row__element_short}>
            <span className={styles.row__element_short_container}>
                {props.children}
            </span>
            </p>
            <p className={styles.row__element_full}>
            <span>
               {props.children === undefined ? "-" : props.children}
            </span>
            </p>
        </div>
    )
}

const Row: React.FC<RowProps> = props => (
    <article className={styles.data__row}>
        <RowElement onHover={props.onHover} className={styles.row__timestamp}>{moment(props.timeMs).format("YYYY-MM-DD THH:mm:ssZ")}</RowElement>
        <RowElement onHover={props.onHover} className={styles.row__requestID}>{props.requestId}</RowElement>
        <RowElement onHover={props.onHover} className={styles.row__projectID}>{props.project}</RowElement>
        <RowElement onHover={props.onHover} className={styles.row__network}>{props.network}</RowElement>
        <RowElement onHover={props.onHover} className={styles.row__responseTime}>{props.resTime}</RowElement>
        <RowElement onHover={props.onHover} className={styles.row__eventType}>{props.eventType}</RowElement>
        <RowElement onHover={props.onHover} className={styles.row__query}>{props.request}</RowElement>
        <RowElement onHover={props.onHover} className={styles.row__httpStatus}>{props.httpStatus}</RowElement>
        <RowElement onHover={props.onHover} className={styles.row__errCode}>{props.errCode}</RowElement>
        <RowElement onHover={props.onHover} className={styles.row__errMessage}>{props.errMessage}</RowElement>
        <RowElement onHover={props.onHover} className={styles.row__bytes}>{props.bytes}</RowElement>
    </article>
);

const LogsBody: React.FC<LogsBodyProps> = ({ logs, isLoading = false }) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const scrollRef = useRef<HTMLElement>(null);

    const firstItemIndex = (currentPage - 1) * maxNumberOfPageElements;

    const onResize = useCallback(() => {
        if (!scrollRef.current) return;

        scrollRef.current.scrollTo( { top: 50 })
    }, [scrollRef.current])

    return (
        <main className={styles.LogsBody}>
            <Backdrop open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <section ref={scrollRef} className={styles.LogsBody__data}>
                <header className={classnames(styles.data__row, styles.data__row_header)}>
                    <p className={styles.row__timestamp}>#</p>
                    <p className={styles.row__requestID}>request ID</p>
                    <p className={styles.row__projectID}>project ID</p>
                    <p className={styles.row__network}>network</p>
                    <p className={styles.row__responseTime}>response time</p>
                    <p className={styles.row__eventType}>event type</p>
                    <p className={styles.row__query}>query</p>
                    <p className={styles.row__httpStatus}>http status</p>
                    <p className={styles.row__errCode}>err code</p>
                    <p className={styles.row__errMessage}>err message</p>
                    <p className={styles.row__bytes}>bytes</p>
                </header>
                {logs !== null ? (
                    logs.sort((a, b) => b.timeMs - a.timeMs)
                        .slice(firstItemIndex, firstItemIndex + maxNumberOfPageElements)
                        .map((log, index, arr) => (
                            <Row {...log} index={firstItemIndex + index + 1} key={log.requestId} onHover={index === arr.length - 1 ? onResize : () => {}} />
                        ))
                ) : (
                    <div className={styles.data__noRequests}>No matching requests found</div>
                )}
            </section>
            {logs !== null && (
                <section className={styles.LogsBody__pagination}>
                    <Pagination
                        count={Math.ceil(logs.length / maxNumberOfPageElements)}
                        page={currentPage}
                        onChange={(e, value) => setCurrentPage(value)}
                    />
                </section>
            )}
        </main>
    );
};

export { LogsBody };
