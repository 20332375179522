import { LIGHT_STYLES } from "./lightStyles";

export enum ThemeNames {
    Light = "light",
    Dark = "dark",
}

export type Theme = {
    name: ThemeNames;
    logo: string;
    symbol: string;
};

export const Themes: { [name in ThemeNames]: Theme } = {
    dark: {
        name: ThemeNames.Dark,
        logo: "/logo-light.svg",
        symbol: "🌛",
    },
    light: {
        name: ThemeNames.Light,
        logo: "/logo-dark.svg",
        symbol: "🌞",
    },
};

export type ThemeStyles = typeof LIGHT_STYLES;
export const defaultTheme = Themes.light;
