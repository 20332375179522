import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CloudAccount, CloudProject, useCloud } from "cloud";
import { listenAccount } from "utils";
import { Col, Container, Row } from "react-bootstrap";
import { AccountSection, NAV, ProjectSection, useLoc, useStyles } from "config";
import { DashboardSidebar } from "screens/dashboard/DashboardSidebar";
import { AccountSummary } from "screens/dashboard/account/AccountSummary";
import { ProjectDetails } from "screens/dashboard/project/ProjectDetails";
import { AccountUsage } from "screens/dashboard/account/AccountUsage";
import { AccountSettings } from "screens/dashboard/account/AccountSettings";
import { GettingStarted } from "screens/dashboard/project/GettingStarted";
import { Feedback } from "screens/dashboard/Feedback";
import classnames from "classnames";

import dashboardStyles from "./Dashboard.module.scss";
const styles: any = dashboardStyles;

function findProject(account?: CloudAccount, id?: string): CloudProject | undefined {
    return account?.projects?.find(x => x.id === id);
}

type DashboardParams = {
    projectId?: string;
    projectSection?: ProjectSection;
    accountSection?: AccountSection;
};

export function Dashboard() {
    const loc = useLoc().dashboard;
    const { dashboard } = useStyles();
    const params = useParams<DashboardParams>();
    const projectId =
        params.projectId && params.projectId !== "undefined" ? params.projectId : undefined;
    const projectSection = params.projectSection || ProjectSection.endpoints;
    const accountSection = params.accountSection;
    const cloud = useCloud();
    const navigate = useNavigate();
    const [account, setAccount] = useState<CloudAccount | undefined>(cloud.account);
    const [project, setProject] = useState<CloudProject | undefined>(
        findProject(account, projectId),
    );
    useEffect(() => {
        if (!cloud.isAuth) {
            navigate(NAV.login);
        }
        setProject(findProject(account, projectId));
        return listenAccount(
            cloud,
            account => {
                setAccount(account);
                setProject(findProject(account, projectId));
            },
            navigate,
        );
    }, [cloud, account, projectId, navigate]);

    if (!account) {
        return <p className="mt-3 m-lg-3">Loading account information...</p>;
    }

    let detailsElement;
    if (!account.isVerified) {
        detailsElement = <p style={dashboard.isNotVerified}>{loc.notVerified}</p>;
    } else if (accountSection) {
        detailsElement = <div>{accountDetails(account, accountSection)}</div>;
    } else if (project) {
        detailsElement = <ProjectDetails project={project} section={projectSection} />;
    } else if (account.projects.length === 0) {
        detailsElement = (
            <div className="ec-card p-4">
                <div className="ec-card-body">{loc.noProjects}</div>
            </div>
        );
    } else {
        detailsElement = (
            <div className="ec-card p-4">
                <div className="ec-card-body">{loc.noSelectedProject}</div>
            </div>
        );
    }

    const rightSection = project ? <GettingStarted project={project} /> : null;

    return (
        <Container fluid className={styles.Dashboard}>
            <main className={classnames(styles.Dashboard__main)}>
                <DashboardSidebar
                    account={account}
                    selectedProjectId={projectId}
                    projectSection={projectSection}
                    accountSection={accountSection}
                />
                <div className={styles.main__content}>{detailsElement}</div>
                {rightSection ? (
                    <aside className={classnames(styles.main__aside, styles.main__aside_right)}>
                        {rightSection}
                    </aside>
                ) : null}
            </main>
            <div className="ec-app-footer mt-5 mb-5 p-5">
                <Row className="justify-content-center mb-4">
                    <Col className="col-auto justify-content-center">
                        <div>© Copyright 2023 EverX Labs</div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col className="col-auto justify-content-center">
                        <Feedback />
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

function accountDetails(account: CloudAccount, section: AccountSection) {
    switch (section) {
        case AccountSection.summary:
            return <AccountSummary account={account} />;
        case AccountSection.usage:
            return <AccountUsage account={account} />;
        case AccountSection.settings:
            return <AccountSettings account={account} />;
    }
}
