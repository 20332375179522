import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Turnstile } from "@marsidev/react-turnstile";
import { NAV, useLoc, useTheme } from "config";
import { useCloud } from "cloud";
import { asyncSubmit, passwordHash } from "utils";
import { FormikErrors, FormikHelpers, useFormik } from "formik";
import { FormAlert, FormField } from "components";
import { FormSelect } from "components/FormField";

import styles from "./Register.module.scss";
import classnames from "classnames";

type Values = {
    email: string;
    password: string;
    confirmPassword: string;
    organizationName: string;
    organizationUrl: string;
    organizationSize: string;
    organizationCategory: string;
    userRole: string;
    captcha: string;
};

// const cloudFareSecret = "0x4AAAAAAAKiqtL1YSjl-SZnsg9IZRqszpM";

export function Register() {
    const cloud = useCloud();
    const theme = useTheme();
    const navigate = useNavigate();

    const loc = useLoc();
    const [error, setError] = useState<string | undefined>();

    const formik = useFormik<Values>({
        initialValues: {
            email: "",
            password: "",
            confirmPassword: "",
            organizationName: "",
            organizationUrl: "",
            organizationSize: "",
            organizationCategory: "",
            userRole: "",
            captcha: "",
        },
        validate: ({
            email,
            password,
            confirmPassword,
            organizationName,
            organizationUrl,
            organizationSize,
            organizationCategory,
            userRole,
            captcha,
        }: Values) => {
            const errors: FormikErrors<Values> = {};
            if (captcha === "") {
                errors.captcha = loc.validation.required;
            }
            if (email === "") {
                errors.email = loc.validation.required;
            }
            if (organizationName === "") {
                errors.organizationName = loc.validation.required;
            }
            if (organizationUrl === "") {
                errors.organizationUrl = loc.validation.required;
            }
            if (organizationSize === "") {
                errors.organizationSize = loc.validation.required;
            }
            if (organizationCategory === "") {
                errors.organizationCategory = loc.validation.required;
            }
            if (userRole === "") {
                errors.userRole = loc.validation.required;
            }
            if (password === "") {
                errors.password = loc.validation.required;
            }
            if (confirmPassword !== password) {
                errors.confirmPassword = loc.validation.confirmPasswordMismatch;
            }
            return errors;
        },
        onSubmit: async (
            {
                email,
                password,
                organizationName,
                organizationUrl,
                organizationSize,
                organizationCategory,
                userRole,
            }: Values,
            { setSubmitting }: FormikHelpers<Values>,
        ) => {
            await asyncSubmit(
                async () => {
                    await cloud.signUp({
                        email,
                        password: passwordHash(email, password),
                        organizationName,
                        organizationUrl,
                        organizationSize,
                        organizationCategory,
                        userRole,
                    });
                    navigate(NAV.projects);
                },
                setSubmitting,
                setError,
            )();
        },
    });

    return (
        <div className={classnames([styles.Register_main, "ec-centered-card p-4"])}>
            <div className="ec-card-header mb-3">{loc.register.title}</div>
            <Form className="register-form" onSubmit={formik.handleSubmit}>
                <FormField
                    field="email"
                    type="email"
                    label={loc.register.email}
                    formik={formik}
                    required
                />

                <Row className={styles.Register_fieldRow}>
                    <Col>
                        <FormField
                            field="password"
                            type="password"
                            label={loc.register.password}
                            formik={formik}
                            required
                        />
                    </Col>
                    <Col>
                        <FormField
                            field="confirmPassword"
                            type="password"
                            label={loc.register.confirmPassword}
                            formik={formik}
                            required
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="ec-card-body mb-2" style={{ maxWidth: "500px" }}>
                            {loc.accountProfile.description}
                        </div>
                        <FormField
                            field="organizationName"
                            type="text"
                            label={loc.accountProfile.organizationName}
                            formik={formik}
                            required
                        />
                        <FormField
                            field="organizationUrl"
                            type="text"
                            label={loc.accountProfile.organizationUrl}
                            formik={formik}
                            required
                        />
                        <FormSelect<Values>
                            id="organizationSize"
                            label={loc.accountProfile.organizationSize}
                            options={loc.accountProfile.organizationSizes}
                            formik={formik}
                        />
                        <FormSelect<Values>
                            id="organizationCategory"
                            label={loc.accountProfile.organizationCategory}
                            options={loc.accountProfile.organizationCategories}
                            formik={formik}
                            required
                        />
                        <FormSelect<Values>
                            id="userRole"
                            label={loc.accountProfile.userRole}
                            options={loc.accountProfile.userRoles}
                            formik={formik}
                        />
                    </Col>
                </Row>
                <div className="d-flex flex-column mb-3">
                    <Turnstile
                        onSuccess={() => formik.setFieldValue("captcha", "success")}
                        onExpire={() => formik.setFieldValue("captcha", "")}
                        onError={() => formik.setFieldValue("captcha", "")}
                        siteKey={
                            process.env.NODE_ENV === "development"
                                ? "0x4AAAAAAAD-I_NpbUvMdceylxa8cTaG2c4"
                                : "0x4AAAAAAAD-I8z30INTf1QW"
                        }
                        options={{
                            theme: theme?.name as any,
                        }}
                        className="w-100"
                    />
                    <Form.Control.Feedback className="d-flex text-center" type="invalid">
                        {formik.errors["captcha"]}
                    </Form.Control.Feedback>
                </div>
                <div className="d-grid gap-2">
                    <Button
                        type="submit"
                        variant="primary"
                        size="lg"
                        disabled={formik.isSubmitting}
                    >
                        {loc.register.register}
                    </Button>
                </div>
            </Form>
            <FormAlert error={error} setError={setError} />
            <div className="ec-card-body text-center mt-3">
                {loc.register.haveAnAccount}&nbsp;
                <Link className="ec-card-link" to={NAV.login}>
                    {loc.register.login}
                </Link>
            </div>
        </div>
    );
}
