import { useState } from "react";
import { CloudProject, useCloud } from "cloud";
import { Button, Form, Modal, ModalProps, Spinner, Stack } from "react-bootstrap";
import { asyncGuard, asyncSubmit, asyncWork } from "utils";
import { useLoc } from "config";
import { FormAlert, FormField } from "components";
import { FormikErrors, FormikHelpers, useFormik } from "formik";

type ProjectSettingsProps = {
    project: CloudProject;
};

interface Values {
    name: string;
}

export function ProjectSettings({ project }: ProjectSettingsProps) {
    const loc = useLoc();
    const cloud = useCloud();

    const formik = useFormik<Values>({
        initialValues: {
            name: project.name,
        },
        onSubmit: async ({ name }: Values, { setSubmitting }: FormikHelpers<Values>) => {
            await asyncSubmit(
                async () => {
                    await cloud.saveProject({
                        id: project?.id ?? "",
                        name,
                    });
                },
                setSubmitting,
                setError,
            )();
        },
        validate: ({ name }: Values) => {
            const errors: FormikErrors<Values> = {};
            if (name === "") {
                errors.name = loc.validation.required;
            }
            return errors;
        },
    });

    const [error, setError] = useState<string | undefined>();
    const [showDelete, setShowDelete] = useState(false);

    return (
        <div className="ec-card p-4">
            <Form onSubmit={formik.handleSubmit}>
                <div className="ec-card-body">
                    <FormField
                        type="text"
                        field="name"
                        label={loc.projectSettings.name}
                        formik={formik}
                    />
                </div>
                <Form.Group>
                    <Stack
                        direction="horizontal"
                        gap={3}
                        className="ec-card-footer"
                        style={{
                            marginLeft: 0,
                            paddingTop: "8pt",
                            marginTop: "12pt",
                            width: "100%",
                        }}
                    >
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={!formik.dirty || formik.isSubmitting}
                        >
                            {formik.isSubmitting ? (
                                <span style={{ paddingRight: "4pt" }}>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </span>
                            ) : null}
                            <span>{loc.projectSettings.save}</span>
                        </Button>
                        <Button
                            variant="outline-danger"
                            onClick={() => {
                                setShowDelete(true);
                            }}
                        >
                            {loc.projectSettings.delete}
                        </Button>
                    </Stack>
                </Form.Group>
            </Form>
            <FormAlert error={error} setError={setError} />
            <DeleteDialog project={project} show={showDelete} onHide={() => setShowDelete(false)} />
        </div>
    );
}

type DeleteDialogProps = ModalProps & {
    project: CloudProject;
};

function DeleteDialog(props: DeleteDialogProps) {
    const loc = useLoc().projectSettings;
    const cloud = useCloud();
    const [deleting, setDeleting] = useState(asyncGuard());
    const [error, setError] = useState<string | undefined>();

    const onDelete = () => {
        asyncWork(
            deleting,
            async () => {
                await cloud.deleteProject(props.project.id);
            },
            setDeleting,
            setError,
        )();
    };

    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{loc.deleteTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{loc.deleteText}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={deleting.processing} variant="danger" onClick={onDelete}>
                    {loc.deleteButton}
                </Button>
                <Button disabled={deleting.processing} onClick={props.onHide}>
                    {loc.deleteCancelButton}
                </Button>
            </Modal.Footer>
            <FormAlert error={error} setError={setError} />
        </Modal>
    );
}
