import { Modal } from "react-bootstrap";
import { Feedback } from "../screens/dashboard/Feedback";

type FeatureRequestAlertProps = {
    visible: boolean;
    setVisible: (visible: boolean) => void;
};

export function FeatureRequestAlert({ visible, setVisible }: FeatureRequestAlertProps) {
    return (
        <Modal centered show={visible} onHide={() => setVisible(false)}>
            <Feedback questionIndex={0} inputWidth="80%" />
        </Modal>
    )
}
