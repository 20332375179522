import { Button, ListGroup, Nav } from "react-bootstrap";
import { NAV, AccountSection, useLoc } from "config";
import { useNavigate, NavLink } from "react-router-dom";
import React from "react";
import { useCloud } from "cloud";

import accountSideMenuStyles from "./AccountSideMenu.module.scss";
import classNames from "classnames";
const styles: any = accountSideMenuStyles;

type AccountSummaryProps = {
    section?: AccountSection;
    isProjectSelected?: string;
};

export function AccountSideMenu(props: AccountSummaryProps & React.HTMLAttributes<HTMLElement>) {
    const loc = useLoc();
    const navigate = useNavigate();
    const cloud = useCloud();
    const { section, isProjectSelected, ...otherProps } = props;
    const linkTo = (itemSection: AccountSection, itemTitle: string) => (
        <Nav.Item>
            <Nav.Link
                className={classNames(
                    "list-group-item",
                    section === itemSection ? "ec-card-list-selected-item" : "ec-card-list-item",
                )}
                eventKey={`${itemSection}/${isProjectSelected}`}
            >
                {itemTitle}
            </Nav.Link>
        </Nav.Item>
    );
    const onSelect = (section: string | null) =>
        navigate(NAV.accountDetails(section as AccountSection));
    return (
        <div {...props} className={styles.AccountSideMenu}>
            <div className="ec-card-header m-3 mb-3 mt-0">{loc.accountSideMenu.title}</div>
            <ListGroup style={{ borderRadius: 0 }} defaultActiveKey={section} onSelect={onSelect}>
                {linkTo(AccountSection.summary, loc.accountSummary.title)}
                {linkTo(AccountSection.usage, loc.accountUsage.title)}
                {linkTo(AccountSection.settings, loc.accountSettings.title)}
                <Button
                    variant="outline-danger"
                    size="sm"
                    className={classNames(
                        styles.AccountSideMenu__logoutButton,
                        "m-3 mt-2 mb-0 h-50",
                    )}
                    onClick={() => cloud.logout()}
                >
                    {loc.accountSummary.logout}
                </Button>
            </ListGroup>
        </div>
    );
}
