import { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import {
    Box,
    Container,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import BarChartIcon from "@mui/icons-material/BarChart";

import { AccountSection, NAV, useConfig, useLoc, useTheme } from "config";
import { CloudAccount, useCloud, useProjectId } from "cloud";
import { listenAccount } from "utils";

import styles from "./AppHeader.module.scss";
import { Telegram } from "@mui/icons-material";
import { FeatureRequestAlert } from "./FeatureRequestAlert";
import { Themes } from "../config/theme";

export function AppHeader() {
    const loc = useLoc().app;
    const cloud = useCloud();
    const { projectId } = useProjectId();
    const [account, setAccount] = useState<CloudAccount | undefined>(cloud.account);
    const theme = useTheme();
    const config = useConfig();
    const [alertVisible, setAlertVisible] = useState(false);
    const [_theme, setTheme] = useState(theme);
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

    useEffect(() => {
        setTheme(config.loadTheme());
        return listenAccount(cloud, account => {
            setAccount(account);
        });
    }, [cloud]);

    const themeSymbol = theme === Themes.light ? Themes.dark.symbol : Themes.light.symbol;

    const accountInfo = account ? account.email : "";

    const getNavLinks = () => {
        return (
            <>
                <NavLink to={NAV.root} className={styles.appHeader__navLink}>
                    {loc.dashboard}
                </NavLink>
                <NavLink to={NAV.explorer} className={styles.appHeader__navLink}>
                    {loc.explorer}
                </NavLink>
                <NavLink to={NAV.docs} className={styles.appHeader__navLink}>
                    {loc.docs}
                </NavLink>
            </>
        );
    };

    const getSettings = () => {
        return (
            <>
                <div className="d-flex">
                    <button
                        className={styles.appHeader__ovalNavLink}
                        onClick={() => setAlertVisible(true)}
                    >
                        {loc.featureRequest}
                    </button>
                    <Tooltip title="Telegram">
                        <IconButton onClick={() => window.open("https://t.me/ever_sdk", "_blank")}>
                            <Telegram sx={{ color: "var(--card-header-color)" }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Usage">
                        <NavLink
                            to={`${NAV.accountDetails(AccountSection.usage)}/${projectId}`}
                            className={styles.appHeader__navLink}
                        >
                            <IconButton>
                                <BarChartIcon sx={{ color: "var(--card-header-color)" }} />
                            </IconButton>
                        </NavLink>
                    </Tooltip>
                </div>
                <div className={styles.appHeader__title}>{accountInfo}</div>
            </>
        );
    };

    return (
        <div className={styles.appHeader__container}>
            <Container maxWidth={false} className={styles.appHeader__announcementBar}>
                <div className={styles.appHeader__announcementBar__title}>
                    01.09.2023 - planned transition to API 1.0. Migration is already available.{" "}
                    <a
                        href="https://docs.evercloud.dev/reference/breaking-changes/migration-guides/graphql-api-1.0-migration"
                        target="_blank"
                    >
                        Find out more
                    </a>
                    .
                </div>
            </Container>
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <Box
                        sx={{
                            flexGrow: 0,
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                    >
                        <img src={theme.logo} width={32} height={32} alt="Logo"></img>
                        <div>
                            <NavLink to={NAV.root} className={styles.appHeader__titleLink}>
                                {loc.header}
                            </NavLink>
                        </div>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            justifyContent: "flex-end",
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={event => setAnchorElNav(event.currentTarget)}
                            color="inherit"
                        >
                            <MenuIcon sx={{ color: "var(--card-header-color)" }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={() => setAnchorElNav(null)}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                            MenuListProps={{
                                sx: {
                                    py: 2,
                                    px: 3,
                                    display: "grid",
                                    backgroundColor: "var(--card-bg)",
                                },
                            }}
                        >
                            <MenuItem sx={{ display: "grid" }} onClick={() => setAnchorElNav(null)}>
                                {getNavLinks()}
                            </MenuItem>
                            <Divider />
                            <MenuItem sx={{ display: "grid" }} onClick={() => setAnchorElNav(null)}>
                                {getSettings()}
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            justifyContent: "center",
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        {account && getNavLinks()}
                    </Box>

                    <div className={styles.appHeader__navSettings}>
                        <Box
                            sx={{
                                flexGrow: 0,
                                alignItems: "center",
                                display: { xs: "none", md: "flex" },
                            }}
                        >
                            {account && getSettings()}
                        </Box>
                        <button
                            className={styles.appHeader__theme}
                            onClick={() => setTheme(config.toggleTheme())}
                        >
                            {themeSymbol}
                        </button>
                    </div>
                </Toolbar>
            </Container>
            <FeatureRequestAlert visible={alertVisible} setVisible={setAlertVisible} />
        </div>
    );
}
