import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { asyncGuard, asyncWork } from "utils";
import { Alert } from "react-bootstrap";
import { NAV, useLoc } from "config";
import { useCloud } from "cloud";

export function Verification() {
    const cloud = useCloud();
    const account = cloud.account;
    const navigate = useNavigate();
    const [verifying, setVerifying] = useState(asyncGuard());
    const [error, setError] = useState<string | undefined>();

    const loc = useLoc().verify;
    const { code } = useParams<{ code: string }>();

    useEffect(() => {
        if (!account?.isVerified && code && !error) {
            asyncWork(
                verifying,
                async () => {
                    await cloud.verify(code);
                    navigate(NAV.projects);
                },
                setVerifying,
                setError,
            )();
        }
    }, [cloud, account, code, navigate, verifying, error]);

    return (
        <div className="vw-100 vh-100 d-flex justify-content-center align-items-center">
            <div>
                <p>{loc.verifying}</p>
                <Alert
                    show={!!error}
                    variant="danger"
                    className="mt-3"
                    dismissible={true}
                    onClose={() => setError(undefined)}
                >
                    {error}
                </Alert>
            </div>
        </div>
    );
}
