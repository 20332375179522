import { CloudAccount } from "cloud";
import { Stack } from "react-bootstrap";
import { useLoc } from "config";
import { AccountProfile } from "screens/dashboard/account/AccountProfile";
import { AccountPassword } from "screens/dashboard/account/AccountPassword";

type AccountSettingsProps = {
    account: CloudAccount;
};

export function AccountSettings({ account }: AccountSettingsProps) {
    const loc = useLoc();
    return (
        <Stack gap={2}>
            <div className="ec-card-header m-4 mb-0 mt-0">{loc.accountPassword.title}</div>
            <AccountPassword account={account} />
            <div className="ec-card-header m-4 mb-0 mt-0">{loc.accountProfile.title}</div>
            <AccountProfile account={account} />
        </Stack>
    );
}
