export const EN_LOC = {
    date: {
        months: {
            January: "January",
            February: "February",
            March: "March",
            April: "April",
            May: "May",
            June: "June",
            July: "July",
            August: "August",
            September: "September",
            October: "October",
            November: "November",
            December: "December",
        },
    },
    validation: {
        required: "Missing required value",
        confirmPasswordMismatch: "Does not match",
    },
    login: {
        title: "Login",
        email: "E-mail address",
        password: "Password",
        login: "Login",
        welcome: "Welcome to Evercloud",
        text:
            "If you already have an account please enter your " +
            "email and password and press Login button.",
        dontHaveAnAccount: "Don’t have an account?",
        signUp: "Sign Up",
        forgotPassword: "Forgot password?",
    },
    register: {
        title: "Create Evercloud Account",
        email: "Email address",
        password: "Password",
        passwordPlaceholder: "Password",
        confirmPassword: "Confirm password",
        confirmPasswordPlaceholder: "Confirm password",
        haveAnAccount: "Have an account?",
        login: "Login",
        register: "Create your account",
    },
    resetPassword: {
        title: "Reset Evercloud Password",
        password: "New Password",
        passwordPlaceholder: "New Password",
        confirmPassword: "Confirm password",
        confirmPasswordPlaceholder: "Confirm password",
        reset: "Reset Password",
    },
    forgotPassword: {
        title: "Forgot your password?",
        email: "E-mail",
        info: "Please specify your email address here and we will send you a password reset link.",
        sent: "Please, check your {email} email for a link to reset your password.",
        reset: "Reset Password",
        backToLogin: "Back to Login",
    },
    addProject: {
        title: "Create Project",
        name: "Name",
        secretRequired: "Secret required",
        add: "Add Project",
    },
    accountSummary: {
        title: "Summary",
        id: "Id",
        email: "e-mail",
        plan: "Plan",
        upgrade: "Upgrade",
        balance: "Balance",
        topup: "Topup",
        logout: "Logout",
    },
    accountUsage: {
        title: "Usage",
        summary: "summary",
        totalComputedUnitsUsed: "Total computed units used",
    },
    accountSettings: {
        title: "Settings",
    },
    accountProfile: {
        title: "Profile",
        description:
            "We’re dedicated to creating meaningful product experiences for you. " +
            "By giving us a small amount of information you’ll help us provide you with specific, " +
            "relevant resources and features for your needs.",
        organizationName: "Organization name",
        organizationUrl: "Organization URL",
        organizationSize: "Organization size",
        organizationSizes: [
            ["SOL", "Solo / Freelance (1)"],
            ["SMS", "Small Startup (2-25)"],
            ["LGS", "Large Startup (26-100)"],
            ["MDM", "Mid Market (101-500)"],
            ["ENT", "Enterprise (501+)"],
        ],
        organizationCategory: "Organization category",
        organizationCategories: [
            ["NFT", "NFT / Collectibles"],
            ["WAL", "Transactions / Wallets"],
            ["GAM", "Gaming"],
            ["DEF", "DeFi / Financial Products"],
            ["DAO", "DAO"],
            ["BLK", "Blockchain / L2"],
            ["MED", "Media"],
            ["SPR", "Sports"],
            ["MTV", "Metaverse"],
            ["INF", "Infrastructure"],
            ["CON", "Consulting"],
        ],
        userRole: "Your Role",
        userRoles: [
            ["DEV", "Engineer / Developer"],
            ["ADM", "IT / SysAdmin / DevOps"],
            ["MAN", "Manager / Director"],
            ["EXC", "Founder / Executive"],
        ],
        select: "Select...",
        save: "Save",
    },
    accountPassword: {
        title: "Password",
        oldPassword: "Old Password",
        password: "New Password",
        confirmPassword: "Confirm New Password",
        save: "Change Password",
        cancel: "Cancel",
    },
    accountSideMenu: {
        title: "Account",
    },
    dashboard: {
        loadFailed: "Failed to load account data",
        notVerified: "Email is not verified yet. Please, check your email",
        endpoints: "Endpoints",
        analytics: "Analytics",
        security: "Security",
        settings: "Settings",
        noProjects: "You don't have projects yet. Add your first project.",
        noSelectedProject: "Please select project from list to see project details.",
    },
    projectAnalytics: {
        title: "{name} Analytics",
        wip: "Work In Progress...",
    },
    projectSummary: {
        gettingStarted: "Getting Started",
        gettingStartedText: "WIP",
        settingsLink: "Project Settings",
        analyticsLink: "Project Analytics",
        endpoints: "Endpoints",
        links: "Links",
        id: "Project Id",
        secret: "Secret",
        secretRequired: "Secret Required",
        secretAuth: "HTTP Authorization",
        secretSdk: "SDK Authorization",
        playground: "GraphQL Playground",
        getMoney: "Get",
        tokenFaucet: "Token faucet",
        updated: "The project has been updated",
        title: "Project Summary",
        pointsPerSec5M: "Points per sec 5M",
        medianResTime5M: "Median res time 5M",
        successRate1H: "Success rate 1H",
        totalRequests1H: "Total requests 1H",
    },
    projectSettings: {
        title: "{name} Settings",
        name: "Name",
        security: "Security",
        secretRequired: "Secret required",
        save: "Save Changes",
        delete: "Delete Project",
        deleteTitle: "Delete Project",
        deleteText: "Are you sure to delete this project?",
        deleteButton: "Delete Project",
        deleteCancelButton: "Cancel",
        topupTitle: "Get Free Funds",
        topupText: "To get free funds please specify account address",
        topupAddress: "To Account Address",
        topup: "Get",
        cancelTopup: "Cancel",
    },
    app: {
        header: "Evercloud Dashboard",
        dashboard: "Dashboard",
        explorer: "Explorer",
        refresh: "Refresh",
        docs: "Docs",
        featureRequest: "Feature request",
    },
    verify: {
        verifying: "Verifying...",
    },
    projectList: {
        title: "Projects",
    },
    props: {
        copyToClipboard: "Copy to clipboard",
    },
    accountSidebar: {
        links: "Useful Links",
        gettingStarted: "Getting Started",
        howToConnect: "How to Connect",
        playground: "GraphQL Playground",
    },
    gettingStarted: {
        title: "Getting Started",
        responseTitle: "Your response should look like:",
        copy: "Copy to clipboard",
        samples: [
            {
                title: "Your First Request",
                purpose: "Retrieve the last masterchain block `hash` and `seqNo`.",
                directions:
                    "Copy and paste the code below into Terminal and your response " +
                    "should be the current block number.",
                request: `curl --location --request POST 'https://mainnet.evercloud.dev/{project}/graphql' \\
{secret-auth}--header 'Content-Type: application/json' \\
--data-raw '{"query":"query{blockchain{blocks(workchain:-1,last:1){edges{node{hash seq_no}}}}}","variables":{}}'
`,
                response: `{
  "data": {
    "blockchain": {
      "blocks": {
        "edges": [
          { "node": { "hash": "9063e339d26f01d09768ce4460d1e276231a659244754deac3afa9ee124f34d5", "seq_no": 13477755 } }
        ]
      }
    }
  }
`,
            },
        ],
    },
    errors: {
        UNAUTHORISED: "Unauthorised",
        ACCOUNT_DOES_NOT_EXIST: "Account does not exist",
        ACCOUNT_DOES_NOT_EXIST_OR_PASSWORD_IS_INCORRECT:
            "Account does not exist or password is incorrect",
        ACCOUNT_IS_NOT_VERIFIED: "Account is not verified",
        ACCOUNT_ALREADY_REGISTERED: "Account already registered",
        INTERNAL_SERVER_ERROR: "Internal server error",
        INVALID_USER_DATA: "Invalid user data",
        INVALID_ACCOUNT_DATA: "Invalid account data",
        INVALID_VERIFICATION_CODE: "Invalid verification code",
        PROJECT_NOT_FOUND: "Project not found",
        PROJECT_SECRET_REQUIRED: "Project secret required",
        PROJECT_SECRET_IS_INCORRECT: "Project secret is incorrect",
        LIMIT_REACHED: "Limit reached",
        OLD_PASSWORD_MISMATCH: "Old password mismatch",
        YOU_CAN_REQUEST_TOKENS_ONLY_ONCE_PER_HOUR_TRY_AGAIN_LATER:
            "You can request tokens only once per hour. Try again later.",
    },
    feedback: {
        questions: [
            ["MIS", "Didn't find the feature you need? Tell us what is missing:"],
            ["IMP", "Have an improvement suggestion? Tell us what you’re thinking:"],
            ["DIF", "Have any difficulty? Tell us about it:"],
        ],
        answer: "Type your answer",
        submit: "Submit",
        sent: "Thank you for the feedback. Your request has been successfully sent.",
    },
};
