import { useState } from "react";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import { asyncGuard, asyncWork } from "utils";
import { useNavigate } from "react-router-dom";
import { useLoc, NAV, ProjectSection } from "config";
import { useCloud } from "cloud";

export function AddProjectButton() {
    const cloud = useCloud();

    const [name, setName] = useState("");
    const [showDialog, setShowDialog] = useState(false);
    const [saving, setSaving] = useState(asyncGuard());
    const [error, setError] = useState<string | undefined>();
    const navigate = useNavigate();
    const loc = useLoc().addProject;

    const onSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity()) {
            asyncWork(
                saving,
                async () => {
                    const id = await cloud.saveProject({
                        name,
                        secretRequired: false,
                    });
                    hideDialog();
                    navigate(NAV.project(id, ProjectSection.endpoints));
                },
                setSaving,
                setError,
            )();
        }
    };

    const hideDialog = () => {
        setName("");
        setError(undefined);
        setShowDialog(false);
    };

    return (
        <div style={{ width: "100%" }}>
            <Button style={{ width: "100%" }} onClick={() => setShowDialog(true)}>
                {loc.add}
            </Button>
            <Modal
                show={showDialog}
                onHide={hideDialog}
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Form onSubmit={onSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{loc.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>{loc.name}</Form.Label>
                            <Form.Control
                                type="text"
                                disabled={saving.processing}
                                required
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Alert
                            show={!!error}
                            variant="danger"
                            className="mt-3"
                            dismissible={true}
                            onClose={() => setError(undefined)}
                        >
                            {error}
                        </Alert>
                        <Button type="submit" variant="primary" disabled={saving.processing}>
                            {saving.processing ? (
                                <span style={{ paddingRight: "4pt" }}>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </span>
                            ) : null}
                            <span>{loc.add}</span>
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}
