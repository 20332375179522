import { Button, Form, Modal, ModalProps, Spinner } from "react-bootstrap";
import { FormAlert, FormField } from "components";
import { CloudNetwork, useCloud } from "cloud";
import { useLoc } from "config";
import { useState } from "react";
import { asyncSubmit } from "utils";
import { FormikErrors, FormikHelpers, useFormik } from "formik";

import { Alert, AlertTitle, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import topupDialogStyles from "./TopupDialog.module.scss";
const styles: any = topupDialogStyles;

type TopupProps = ModalProps & {
    network?: CloudNetwork;
};

type TopupValues = {
    address: string;
};

export function TopupDialog(props: TopupProps) {
    const loc = useLoc();
    const cloud = useCloud();
    const [error, setError] = useState<string | undefined>();
    const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);

    const formik = useFormik<TopupValues>({
        initialValues: {
            address: "",
        },
        onSubmit: async (
            { address }: TopupValues,
            { setSubmitting }: FormikHelpers<TopupValues>,
        ) => {
            await asyncSubmit(
                async () => {
                    setError(undefined);
                    if (props.network) {
                        await cloud.topupAddress(props.network, address);
                    }

                    props.onHide?.();

                    setIsSuccessAlertOpen(true);
                    setTimeout(() => setIsSuccessAlertOpen(false), 5000);
                },
                setSubmitting,
                setError,
            )();
        },
        validate: ({ address }: TopupValues) => {
            const errors: FormikErrors<TopupValues> = {};
            if (address === "") {
                errors.address = loc.validation.required;
            }
            return errors;
        },
    });

    const onHide = () => {
        if (!formik.isSubmitting) {
            props.onHide?.();
        }
    };

    return (
        <>
            <Collapse in={isSuccessAlertOpen} className={styles.Collapse}>
                <Alert
                    severity="success"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setIsSuccessAlertOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    <AlertTitle>Success</AlertTitle>
                    Your request has been accepted. Within a few seconds your address will be
                    replenished
                </Alert>
            </Collapse>
            <Modal
                {...props}
                className="ec-modal"
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onExit={() => {
                    setError(undefined);
                    formik.resetForm();
                }}
            >
                <Form onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {loc.projectSettings.topupTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{loc.projectSettings.topupText}</p>
                        <FormField
                            type="text"
                            field="address"
                            label={loc.projectSettings.topupAddress}
                            formik={formik}
                        />
                        <FormAlert
                            error={
                                error === "Free funds limits reached. Try again later."
                                    ? loc.errors
                                          .YOU_CAN_REQUEST_TOKENS_ONLY_ONCE_PER_HOUR_TRY_AGAIN_LATER
                                    : error
                            }
                            setError={setError}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" disabled={!formik.dirty || formik.isSubmitting}>
                            {formik.isSubmitting ? (
                                <span style={{ paddingRight: "4pt" }}>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </span>
                            ) : null}
                            <span>{loc.projectSettings.topup}</span>
                        </Button>
                        <Button
                            variant="outline-secondary"
                            disabled={formik.isSubmitting}
                            onClick={onHide}
                        >
                            {loc.projectSettings.cancelTopup}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}
