type ChangeFilterType = <KeyType extends keyof ProjectLogFilters>(
    key: KeyType,
    value?: ProjectLogFilters[KeyType] | null,
) => void;

enum TimeRange {
    "Last5Min" = "Last5Min",
    "LastHour" = "LastHour",
    "LastDay" = "LastDay",
    "Last7Days" = "Last7Days",
}

enum CustomTimeRangeBorder {
    From = "timestampFrom",
    To = "timestampTo",
}

enum Status {
    All = "all",
    ErrorsOnly = "errorsOnly",
}

enum DurationVariant {
    Min = "min",
    Max = "max",
}

type CustomTimeRangeType = { [key in CustomTimeRangeBorder]?: number };

type DurationType = { [key in DurationVariant]?: number };

type ProjectSummaryType = {
    pointsPerSec5M: number;
    medianResTime5M: number;
    successRate1H: number;
    totalRequests1H: number;
};

type ProjectLogRecord = {
    requestId: string;
    project: string;
    network: string;
    timeMs: number;
    resTime: number;
    eventType: number;
    protocol: number;
    request: string;
    httpStatus: number;
    errCode: string;
    errMessage: string;
    bytes: number;
};

type ProjectLogRecordEdge = {
    cursor: string;
    node: ProjectLogRecord;
};

type ProjectLogsType = {
    pageInfo: {
        hasPreviousPage: boolean;
        hasNextPage: boolean;
        startCursor: string;
        endCursor: string;
    };
    edges: ProjectLogRecordEdge[];
    nodes: ProjectLogRecord[];
};

type ProjectLogFilters = {
    projectId?: string;
    timeRange?: TimeRange;
    timestampFrom: number;
    timestampTo: number;
    search?: string;
    errorsOnly?: boolean;
    resTimeMin?: number;
    resTimeMax?: number;
    first?: number;
    after?: string;
    last?: number;
    before?: string;
};

export { TimeRange, CustomTimeRangeBorder, Status, DurationVariant };
export type {
    ChangeFilterType,
    CustomTimeRangeType,
    ProjectLogRecord,
    ProjectSummaryType,
    ProjectLogFilters,
    ProjectLogsType,
};
