import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { NAV } from "config";
import { useCloud } from "cloud";

import { ProjectLogFilters, ProjectLogRecord, ProjectSummaryType, TimeRange } from "./types";
import { Filters } from "./components/Filters";
import { LogsBody } from "./components/LogsBody";
import { noValuePlaceholder } from "./constants";

import styles from "./Explorer.module.scss";

const Explorer: React.FC = () => {
    const cloud = useCloud();
    const { isAuth } = cloud;
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState<boolean>(false);
    const [logs, setLogs] = useState<ProjectLogRecord[] | null>(null);
    const [filters, setFilters] = useState<ProjectLogFilters>({
        timestampFrom: Math.floor(Date.now() / 1000 - 300),
        timestampTo: Math.floor(Date.now() / 1000),
        timeRange: TimeRange.Last5Min,
        errorsOnly: false,
    });

    useEffect(() => {
        if (isAuth) {
            const filtersResult = { ...filters };
            const { timeRange } = filters;

            if (timeRange === TimeRange.Last5Min) {
                filtersResult.timestampFrom = Math.floor(Date.now() / 1000 - 300);
                filtersResult.timestampTo = Math.floor(Date.now() / 1000);
            } else if (timeRange === TimeRange.LastHour) {
                filtersResult.timestampFrom = Math.floor(Date.now() / 1000 - 3600);
                filtersResult.timestampTo = Math.floor(Date.now() / 1000);
            } else if (timeRange === TimeRange.LastDay) {
                filtersResult.timestampFrom = Math.floor(Date.now() / 1000 - 3600 * 24);
                filtersResult.timestampTo = Math.floor(Date.now() / 1000);
            } else if (timeRange === TimeRange.Last7Days) {
                filtersResult.timestampFrom = Math.floor(Date.now() / 1000 - 3600 * 24 * 7);
                filtersResult.timestampTo = Math.floor(Date.now() / 1000);
            }

            setLoading(true);
            cloud
                .getAllProjectLogs({
                    ...filtersResult,
                })
                .then(res => {
                    setLoading(false);
                    if (res.analytics.projectLogs) {
                        setLogs(res.analytics.projectLogs.nodes);
                    } else {
                        setLogs(null);
                    }
                })
                .catch(e => {
                    setLoading(false);
                    setLogs(null);
                });
        }
    }, [isAuth, filters]);

    return (
        <main className={styles.Explorer}>
            <section className={styles.Explorer__logs}>
                <Filters filters={filters} onChange={v => setFilters(v)} isLoading={isLoading} />
                <LogsBody logs={logs} isLoading={isLoading} />
            </section>
        </main>
    );
};

export { Explorer };
