import { ListGroup, Stack } from "react-bootstrap";
import {CloudProject, useProjectId} from "cloud";
import { useNavigate } from "react-router-dom";
import { NAV, ProjectSection, useLoc } from "config";
import { AddProjectButton } from "screens/dashboard/AddProjectButton";
import React, {useEffect} from "react";
import classNames from "classnames";

import projectListStyles from "./ProjectList.module.scss";
const styles: any = projectListStyles;

type ProjectListProps = {
    projects: CloudProject[];
    selected?: string;
    section?: ProjectSection;
};

export function ProjectList(props: ProjectListProps & React.HTMLAttributes<HTMLElement>) {
    const loc = useLoc().projectList;
    const navigate = useNavigate();
    const { setProjectId } = useProjectId();
    const { projects, selected, section } = props;

    useEffect(() => {
        setProjectId(selected || undefined)
    }, [selected, setProjectId]);

    return (
        <Stack {...props}>
            <div className="ec-card-header m-3 mb-2 mt-2">{loc.title}</div>
            <div className={classNames(styles.ProjectList__list, "ec-card-list")}>
                <ListGroup
                    style={{ borderRadius: 0 }}
                    defaultActiveKey={selected}
                    onSelect={id => {
                        if (id) {
                            navigate(NAV.project(id, section as ProjectSection | undefined));
                        }
                    }}
                >
                    {projects.map(project => {
                        return (
                            <ListGroup.Item
                                key={project.id}
                                eventKey={project.id}
                                className={
                                    project.id === selected
                                        ? "ec-card-list-selected-item"
                                        : "ec-card-list-item"
                                }
                            >
                                {project.name}
                            </ListGroup.Item>
                        );
                    })}
                </ListGroup>
            </div>
            <div className={classNames(styles.ProjectList__addProjectButton, "m-3")}>
                <AddProjectButton />
            </div>
        </Stack>
    );
}
